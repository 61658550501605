<template>
  <AppCard class="mt-4">
    <h1 class="text-2xl font-bold">Membership Transactions</h1>
    <DataTable
      :value="processedTransactions"
      striped-rows
      row-hover
      sort-field="scheduled_at"
      :sort-order="-1"
    >
      <Column header="Amount">
        <template #body="slotProps">
          {{ getFormattedCurrency(slotProps.data.amount) }}
        </template>
      </Column>
      <Column header="Date">
        <template #body="slotProps">
          {{ new Date(slotProps.data.scheduled_at).toLocaleDateString() }}
        </template>
      </Column>
      <Column header="Transaction Type">
        <template #body="slotProps">
          {{ formatPaymentMethodType(slotProps.data.payment_method_type) }}
        </template>
      </Column>
      <Column header="Status">
        <template #body="slotProps">
          {{ getScheduledPaymentStatusDisplayValue(slotProps.data.status) }}
        </template>
      </Column>
      <Column header="Refund">
        <template #body="slotProps">
          <button
            v-if="!slotProps.data.refund_transaction_created_at"
            class="text-blue-600 hover:text-blue-800 underline"
            @click="confirmRefund(slotProps.data)"
          >
            Refund
          </button>
          <span
            v-if="slotProps.data.refund_transaction_created_at"
            class="text-green-600"
          >
            Refunded
            {{
              new Date(
                slotProps.data.refund_transaction_created_at
              ).toLocaleDateString("en-US")
            }}
          </span>
        </template>
      </Column>
    </DataTable>
  </AppCard>
  <ConfirmDialog group="refund">
    <template #container="{ message, acceptCallback, rejectCallback }">
      <div class="flex flex-col p-8 bg-surface-0 dark:bg-surface-900 rounded">
        <h3 class="text-xl font-bold mb-4">{{ message.header }}</h3>
        <p class="whitespace-pre-line mb-6">{{ message.message }}</p>
        <div class="flex justify-end gap-6">
          <button
            type="button"
            class="text-gray-600 hover:text-gray-800"
            @click="rejectCallback"
          >
            Cancel
          </button>
          <Button @click="acceptCallback"> Yes, refund </Button>
        </div>
      </div>
    </template>
  </ConfirmDialog>
</template>
<script setup lang="ts">
import type { SubscriberMembership, ScheduledPayment } from "@/api/model";
import AppCard from "@/shared/components/AppCard.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { getFormattedCurrency } from "@/shared/utils/helpers";
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
import Button from "primevue/button";
import { getScheduledPaymentStatusDisplayValue } from "../../offerings/helpers";
import { useRefundScheduledPayment } from "@/modules/payments/composables/useRefundScheduledPayment";
import { useToast } from "primevue/usetoast";
import { computed } from "vue";

const props = defineProps<{
  subscriberMembership: SubscriberMembership;
}>();

const confirm = useConfirm();
const toast = useToast();

const processedTransactions = computed(() => {
  return props.subscriberMembership.payment_plan.past_transactions;
});

const { refundScheduledPayment, errorMessage } = useRefundScheduledPayment();

const formatPaymentMethodType = (type: string): string => {
  return type.toLowerCase() === "card" ? "Card" : "ACH";
};

const handleRefund = async (payment: ScheduledPayment) => {
  await refundScheduledPayment(props.subscriberMembership.id, payment.id);

  if (errorMessage.value) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Our team has been notified and we are looking into it",
      life: 3000,
    });
    return;
  }

  toast.add({
    severity: "success",
    summary: "Success",
    detail: "Payment has been refunded successfully",
    life: 3000,
  });
};

const confirmRefund = (payment: ScheduledPayment) => {
  confirm.require({
    group: "refund",
    header: "Confirm Refund",
    message: `Are you sure you want to refund this transaction?\n\n${getFormattedCurrency(payment.amount)} will be refunded to ${props.subscriberMembership.associated_contact.name}`,
    acceptLabel: "Yes, refund",
    rejectLabel: "Cancel",
    accept: () => {
      handleRefund(payment);
    },
  });
};
</script>
