/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */

/**
 * * `team` - Team
* `tournament` - Tournament
* `camp` - Camp
* `tryouts` - Tryouts
* `lesson` - Lesson
* `other` - Other
 */
export type OfferingCategoryEnum = typeof OfferingCategoryEnum[keyof typeof OfferingCategoryEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OfferingCategoryEnum = {
  team: 'team',
  tournament: 'tournament',
  camp: 'camp',
  tryouts: 'tryouts',
  lesson: 'lesson',
  other: 'other',
} as const;
