<template>
  <form v-if="validUserId" class="relative">
    <FixedActionBar
      button-type="submit"
      :is-loading="isSubmitting"
      :show-create="isCreateForm"
      :show-delete="!isCreateForm && isUserActive"
      class="mb-6"
      @delete="
        async () => ((await v$.$validate()) ? confirmDelete(userId) : null)
      "
      @create="
        async () =>
          (await v$.$validate()) ? createUserThenRedisplay(form) : null
      "
    />
    <AppCard>
      <div class="text-3xl">
        <template v-if="!isUserActive">
          <span class="text-red-500 mr-6">User is deleted</span>
          <Button label="Restore" @click="restoreUserThenRedisplay(userId)" />
        </template>
      </div>

      <Tabs value="0">
        <TabList>
          <Tab value="0" class="font-bold text-[var(--p-surface-700)]">
            About
          </Tab>
          <Tab
            v-if="showAssociatedContacts"
            value="1"
            class="font-bold text-[var(--p-surface-700)]"
          >
            Associated Contacts
          </Tab>
          <Tab
            v-if="!isCreateForm"
            value="2"
            class="font-bold text-[var(--p-surface-700)]"
          >
            Workouts
          </Tab>
          <Tab
            v-if="!isCreateForm"
            value="3"
            class="font-bold text-[var(--p-surface-700)]"
          >
            Role & Permissions
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel header="About" value="0">
            <div class="flex justify-end flex-wrap mb-6">
              <Button
                v-if="!isCreateForm"
                label="Save"
                @click="
                  async () =>
                    (await v$.$validate())
                      ? updateUserThenRedisplay(userId, form)
                      : null
                "
              />
            </div>

            <div class="space-y-4">
              <div class="flex items-center space-x-4">
                <label
                  for="profile-photo"
                  class="block w-64 text-md font-medium text-gray-700"
                  >Profile Photo</label
                >
                <div>
                  <SingleImageUploaderAndDisplay
                    id="profile-photo"
                    label=""
                    :disclaimer-text="`Allowed file types: ${allowedImageFileExtensionsByKey}.`"
                    :image-data="imageData"
                    :thumbnail-width="280"
                    :organization-id="form.organization_id || defaultOrg.id"
                    :allow-delete-button="false"
                    class="mt-1 w-64"
                    @upload-complete="updateProfileImage"
                    @upload-failed="uploadFailed"
                  />
                </div>
              </div>

              <div class="flex items-center space-x-4">
                <label
                  for="name"
                  class="block w-64 text-md font-medium text-gray-700"
                  >Name</label
                >
                <div>
                  <InputText
                    id="name"
                    v-model="form.name"
                    type="text"
                    placeholder="Name"
                    class="mt-1 block w-64 border-gray-300 rounded-md shadow-sm"
                    @blur="v$.name.$touch"
                  />
                  <div>
                    <small
                      v-if="v$ErrorMessage(v$.name.$errors)"
                      class="text-red-500"
                    >
                      {{ v$ErrorMessage(v$.name.$errors) }}
                    </small>
                  </div>
                </div>
              </div>

              <div class="flex items-center space-x-4">
                <label
                  for="email"
                  class="block w-64 text-md font-medium text-gray-700"
                  >Email</label
                >
                <div>
                  <InputText
                    id="email"
                    v-model="form.email"
                    type="text"
                    placeholder="Email@example.com"
                    class="mt-1 block w-64 border-gray-300 rounded-md shadow-sm"
                    @blur="v$.email.$touch"
                  />
                  <div>
                    <small
                      v-if="v$ErrorMessage(v$.email.$errors)"
                      class="text-red-500"
                    >
                      {{ v$ErrorMessage(v$.email.$errors) }}
                    </small>
                  </div>
                </div>
              </div>

              <div class="flex items-center space-x-4">
                <label
                  for="phone"
                  class="block w-64 text-md font-medium text-gray-700"
                  >Phone</label
                >
                <div>
                  <InputText
                    id="phone"
                    v-model="form.phone_number"
                    type="text"
                    placeholder="5555555555"
                    class="mt-1 block w-64 border-gray-300 rounded-md shadow-sm"
                  />
                  <div>
                    <small
                      v-if="v$ErrorMessage(v$.phone_number.$errors)"
                      class="text-red-500"
                    >
                      {{ v$ErrorMessage(v$.phone_number.$errors) }}
                    </small>
                  </div>
                </div>
              </div>

              <div v-if="isStaff" class="flex items-center space-x-4">
                <label
                  for="organization"
                  class="block w-64 text-md font-medium text-gray-700"
                  >Organization</label
                >
                <div class="col">
                  <Select
                    v-model="form.organization_id"
                    :options="organizations"
                    placeholder="Select organization"
                    option-label="name"
                    option-value="id"
                    class="mt-1 block w-64 border-gray-300 rounded-md shadow-sm"
                    :disabled="!isCreateForm"
                  />
                  <div>
                    <small
                      v-if="v$ErrorMessage(v$.organization_id.$errors)"
                      class="text-red-500"
                    >
                      {{ v$ErrorMessage(v$.organization_id.$errors) }}
                    </small>
                  </div>
                </div>
              </div>

              <div v-if="userId" class="flex items-center space-x-4">
                <label
                  for="teams"
                  class="block w-64 text-md font-medium text-gray-700"
                >
                  Teams
                </label>
                <div class="flex flex-col">
                  <div v-for="team in teams" :key="team?.id">
                    <p class="flex items-center">
                      <span
                        @click="
                          async () =>
                            await router.push({
                              name: ROUTE_NAME.TEAM_UPDATE,
                              params: {
                                teamId: team.id,
                                id: form.organization_id,
                              },
                            })
                        "
                        >{{ team.name }}</span
                      >
                      <Button
                        icon="pi pi-times"
                        severity="danger"
                        text
                        rounded
                        aria-label="Cancel"
                        class="items-center"
                        @click="confirmDeleteUserFromTeam(userId, team.id)"
                      />
                    </p>
                  </div>
                  <div v-if="!teams.length" class="mb-6">
                    Not assigned to any teams.
                  </div>

                  <Select
                    v-model="addTeamForm.add_to_team"
                    :options="allTeams"
                    option-label="name"
                    option-value="id"
                    placeholder="Add Team"
                    class="mt-1 block w-64 border-gray-300 rounded-md shadow-sm"
                    @change="
                      async () =>
                        (await addteamformv$.$validate())
                          ? addUserToTeamThenRedisplay(
                              addTeamForm.add_to_team,
                              userId
                            )
                          : null
                    "
                  />

                  <div>
                    <small
                      v-if="v$ErrorMessage(addteamformv$.add_to_team.$errors)"
                      class="text-red-500"
                    >
                      {{ v$ErrorMessage(addteamformv$.add_to_team.$errors) }}
                    </small>
                  </div>
                </div>
              </div>

              <div class="flex items-center space-x-4">
                <div
                  v-if="!isCreateForm"
                  label="Set password"
                  class="col-fixed label-fixed-width is-anchortag"
                  link
                  @click="passwordSetVisible = true"
                >
                  Set password
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel
            v-if="showAssociatedContacts"
            header="Associated Contacts"
            value="1"
          >
            <div class="space-y-4">
              <div class="flex justify-end flex-wrap mb-6">
                <Button
                  v-if="!isCreateForm"
                  label="Add Contact"
                  @click="async () => newAssociatedContact()"
                />
              </div>
              <DataTable
                v-if="!isCreateForm"
                :value="associatedContacts"
                striped-rows
                row-hover
              >
                <Column field="name" header="Contact Name">
                  <template #body="{ data }">
                    <div>
                      <div v-if="data && data.name" class="truncate">
                        <span>{{ data.name }}</span>
                      </div>
                    </div>
                  </template>
                </Column>
                <Column field="email" header="Email">
                  <template #body="{ data }">
                    <div>
                      <div
                        v-if="data && data.email"
                        class="cursor-pointer"
                        data-testid="edit-associated-contact"
                        @click="editAssociatedContact(data)"
                      >
                        <span class="mr-2">{{ data.email }}</span>
                        <i class="pi pi-pencil" />
                      </div>
                    </div>
                  </template>
                </Column>
                <Column
                  field="primary_contact"
                  header="Default Invoice Recipient"
                >
                  <template #body="{ data }">
                    <div class="pl-16">
                      <div v-if="data && data.primary_contact">
                        <span class="text-green-500">Yes</span>
                      </div>
                    </div>
                  </template>
                </Column>
                <Column field="removeAssociatedContact" header="Remove">
                  <template #body="{ data }">
                    <Button
                      icon="pi pi-times"
                      severity="danger"
                      text
                      rounded
                      aria-label="Cancel"
                      class="flex items-center justify-center"
                      @click="confirmDeleteAssociatedContact(data)"
                    />
                  </template>
                </Column>
              </DataTable>
            </div>
          </TabPanel>

          <TabPanel v-if="!isCreateForm" header="Workouts" value="2">
            <WorkoutTab :user-id="userId" />
          </TabPanel>

          <TabPanel v-if="!isCreateForm" header="Role & Permissions" value="3">
            <div class="space-y-4">
              <div class="flex justify-end flex-wrap mb-6">
                <Button
                  v-if="!isCreateForm"
                  label="Save"
                  @click="
                    async () =>
                      (await v$.$validate())
                        ? updateUserThenRedisplay(userId, form)
                        : null
                  "
                />
              </div>

              <div class="flex items-center space-x-4">
                <label
                  for="is_admin"
                  class="block w-64 text-md font-medium text-gray-700"
                  >Admin</label
                >
                <div class="col">
                  <ToggleSwitch id="is_admin" v-model="form.is_admin" />
                </div>
              </div>

              <div class="flex items-center space-x-4">
                <label
                  for="can_record_screen"
                  class="block w-64 text-md font-medium text-gray-700"
                  >Can record screen</label
                >
                <div class="col">
                  <span v-if="!isStaff">
                    <template v-if="form.can_record_screen === true">
                      Yes
                    </template>
                    <template v-if="form.can_record_screen === false">
                      No
                    </template>
                  </span>
                  <ToggleSwitch
                    v-if="isStaff"
                    id="can_record_screen"
                    v-model="form.can_record_screen"
                    :disabled="!isStaff || form.is_admin || isCoach"
                  />
                </div>
              </div>
            </div>
            <hr v-if="isCoach" class="mb-6 mt-20" />
            <div class="space-y-4">
              <div class="flex items-center justify-between space-x-4">
                <h2 v-if="isCoach" class="text-2xl font-semibold">Coach</h2>
                <div v-if="isCoach" class="flex justify-end flex-wrap mb-6">
                  <Button
                    label="Save"
                    @click="
                      async () =>
                        (await formcoachv$.$validate())
                          ? updateCoachingThenRedisplay(coachId, formCoach)
                          : null
                    "
                  />
                </div>
              </div>
              <div class="flex items-center space-x-4">
                <label
                  for="coach"
                  class="block w-64 text-md font-medium text-gray-700"
                >
                  Coach
                </label>
                <div>
                  <Button
                    v-if="!isCoach"
                    label="Make a coach"
                    link
                    class="pl-0"
                    @click="
                      async () => {
                        if (form.organization_id) {
                          await makeACoachThenRedisplay(
                            userId,
                            form.organization_id
                          );
                        }
                      }
                    "
                  />
                  <Button
                    v-if="isCoach"
                    label="Remove as coach"
                    link
                    class="pl-0"
                    @click="
                      async () => await removeAsCoachThenRedisplay(coachId)
                    "
                  />
                </div>
              </div>
              <div class="space-y-4">
                <div v-if="isCoach" class="flex items-center space-x-4">
                  <label
                    for="snippet"
                    class="block w-64 text-md font-medium text-gray-700"
                    >Snippet</label
                  >
                  <div class="col">
                    <Textarea
                      v-model="formCoach.snippet"
                      placeholder="Sample Snippet"
                      auto-resize
                      rows="5"
                      cols="50"
                    />
                  </div>
                </div>

                <div v-if="isCoach" class="flex items-center space-x-4">
                  <label
                    for="bio"
                    class="block w-64 text-md font-medium text-gray-700"
                    >Bio</label
                  >
                  <div class="col">
                    <Textarea
                      v-model="formCoach.bio"
                      placeholder="Sample Bio"
                      auto-resize
                      rows="5"
                      cols="50"
                    />
                  </div>
                </div>

                <div v-if="isCoach" class="flex items-center space-x-4">
                  <label
                    for="is_available"
                    class="block w-64 text-md font-medium text-gray-700"
                    >Available</label
                  >
                  <div class="col">
                    <ToggleSwitch
                      id="is_available"
                      v-model="formCoach.is_available"
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </AppCard>
  </form>

  <AppCard v-if="!validUserId"> User not found. </AppCard>

  <Dialog
    v-model:visible="passwordSetVisible"
    modal
    header="Set Password"
    :style="{ maxWidth: '500px' }"
    @show="
      vp$.$reset();
      passwordForm.password = undefined;
      passwordForm.password2 = undefined;
    "
  >
    <div class="formgrid">
      <div class="field">
        <label for="password">Password</label>
        <InputText
          id="password"
          v-model="passwordForm.password"
          type="password"
          class="text-base text-color bg-surface-0 dark:bg-surface-900 p-2 border border-solid border-surface rounded-border appearance-none outline-0 focus:border-primary w-full"
        />
        <div>
          <small
            v-if="v$ErrorMessage(vp$.password.$errors)"
            class="text-red-500"
          >
            {{ v$ErrorMessage(vp$.password.$errors) }}
          </small>
        </div>
      </div>

      <div class="field mt-2">
        <label for="password2">Retype Password</label>
        <InputText
          id="password2"
          v-model="passwordForm.password2"
          type="password"
          class="text-base text-color bg-surface-0 dark:bg-surface-900 p-2 border border-solid border-surface rounded-border appearance-none outline-0 focus:border-primary w-full"
        />
        <div>
          <small
            v-if="v$ErrorMessage(vp$.password2.$errors)"
            class="text-red-500"
          >
            {{ v$ErrorMessage(vp$.password2.$errors) }}
          </small>
        </div>
      </div>

      <Button
        label="Set Password"
        class="mt-6"
        @click="
          async () => ((await vp$.$validate()) ? await resetPassword() : null)
        "
      />
    </div>
  </Dialog>
  <Dialog
    v-model:visible="showContactModal"
    modal
    :header="contactModalHeader"
    :style="{ maxWidth: '680px' }"
    @show="vac$.$reset()"
  >
    <div class="formgrid">
      <div class="mb-3">Player: {{ dbUserData.name }}</div>

      <div
        v-if="showDisclaimer"
        className="border-l-2 border-blue-400 p-4 my-4 rounded-r"
      >
        <div className="flex items-start">
          <div className="ml-3">
            <p className="text-sm font-medium mb-2">
              Updating this contact's information will affect all associated
              records, including:
            </p>
            <ul className="list-disc list-inside text-sm  space-y-1 ml-2">
              <li>Active invoices and payment schedules</li>
              <li>Saved payment methods</li>
              <li>Payment history and records</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="flex space-x-4">
        <div class="flex-1 mr-2 field">
          <label for="contactName">Contact Name</label>
          <InputText
            id="contactName"
            v-model="associatedContactForm.name"
            type="text"
            placeholder="Name"
            maxlength="255"
            class="text-base text-color bg-surface-0 dark:bg-surface-900 p-2 border border-solid border-surface rounded-border appearance-none outline-0 focus:border-primary w-full"
          />
          <div>
            <small
              v-if="v$ErrorMessage(vac$.name.$errors)"
              class="text-red-500"
            >
              {{ v$ErrorMessage(vac$.name.$errors) }}
            </small>
          </div>
        </div>

        <div class="flex-1 field">
          <label for="contactEmail">Email</label>
          <InputText
            id="contactEmail"
            v-model="associatedContactForm.email"
            type="text"
            placeholder="Email"
            maxlength="255"
            class="text-base text-color bg-surface-0 dark:bg-surface-900 p-2 border border-solid border-surface rounded-border appearance-none outline-0 focus:border-primary w-full"
          />
          <div>
            <small
              v-if="v$ErrorMessage(vac$.email.$errors)"
              class="text-red-500"
            >
              {{ v$ErrorMessage(vac$.email.$errors) }}
            </small>
          </div>
          <div>
            <small v-if="contactHasOtherProfiles">
              Also associated with {{ otherProfileNames }}.
            </small>
          </div>
        </div>
      </div>

      <div class="flex mt-12 justify-between">
        <div class="flex align-items-top">
          <Checkbox
            id="primaryContact"
            v-model="associatedContactForm.primary_contact"
            :binary="true"
          />
          <div>
            <small
              v-if="v$ErrorMessage(vac$.primary_contact.$errors)"
              class="text-red-500"
            >
              {{ v$ErrorMessage(vac$.primary_contact.$errors) }}
            </small>
          </div>
          <label class="ml-2" for="primaryContact"
            >Make default invoice recipient</label
          >
        </div>

        <div class="flex flex-1 justify-end">
          <Button
            label="Save"
            class="ml-auto"
            @click="async () => await saveAssociatedContact()"
          />
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from "primevue/dialog";
import SingleImageUploaderAndDisplay from "@/shared/components/SingleImageUploaderAndDisplay.vue";
import { allowedImageFileExtensionsByKey } from "@/shared/constants/uploader";
import InputText from "primevue/inputtext";
import ToggleSwitch from "primevue/toggleswitch";
import Textarea from "primevue/textarea";
import Button from "primevue/button";
import Select from "primevue/select";
import Checkbox from "primevue/checkbox";
import TabPanel from "primevue/tabpanel";
import TabPanels from "primevue/tabpanels";
import Tabs from "primevue/tabs";
import TabList from "primevue/tablist";
import Tab from "primevue/tab";
import DataTable from "primevue/datatable";
import WorkoutTab from "./WorkoutTab.vue";

import Column from "primevue/column";
import FixedActionBar from "@/shared/components/FixedActionBar.vue";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import { v$ErrorMessage } from "@/shared/utils/helpers";
import { computed, onMounted, reactive, ref, watch, type Ref } from "vue";
import {
  required,
  sameAs,
  helpers,
  minLength,
  maxLength,
  numeric,
  email,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useRoute } from "vue-router";
import router from "@/router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import AppCard from "@/shared/components/AppCard.vue";
import {
  getUser,
  createUser,
  updateUser,
  restoreUser,
  deleteUser,
  removeAsCoach,
  updateCoach,
  makeACoach,
  getAssociatedContacts,
  addContactToUser,
  removeContactFromUser,
  updateAssociatedContactApi,
} from "@/modules/users/api";
import {
  getTeams,
  addUserToTeam,
  deleteUserFromTeam,
} from "@/modules/teams/api";

import { storeToRefs } from "pinia";
import { useAuthStore } from "@/modules/auth/stores/auth";
import { useCurrentUser } from "@/composables/useCurrentUser";
import { useOrganizationStore } from "@/modules/organizations/stores/organizationsStore";
import { updateUserProfile } from "@/modules/profile/api";
import type {
  AdminProfile,
  AdminProfileAssociatedContact,
  Asset,
  ProfileTeams,
} from "../../../api/model";
import { addMixpanelEvent, EVENT_NAMES } from "@/shared/utils/analytics";

const authStore = useAuthStore();
const { isStaff, defaultOrg } = authStore;
const { isSingleOrgUser } = storeToRefs(authStore);

const { getAllOrganizations } = useOrganizationStore();
let imageData = ref();
const emit = defineEmits(["user"]);

const { organizations } = storeToRefs(useOrganizationStore());
const { fetchOrganizations, defaultOrganization } = useCurrentUser();

const associatedContacts: Ref<AdminProfileAssociatedContact[]> = ref([]);
const showContactModal = ref(false);
const contactModalHeader = computed(() =>
  selectedContact.value.id ? `Edit Contact` : "Create New Contact"
);
const showAssociatedContacts = computed(
  () =>
    defaultOrganization.value?.payments_enabled &&
    !isCoach.value &&
    !isCreateForm.value
);

const selectedContact = ref<
  Pick<
    AdminProfileAssociatedContact,
    "email" | "name" | "primary_contact" | "profiles"
  > & {
    id: number | null;
  }
>({
  id: 0,
  name: "",
  email: "",
  primary_contact: false,
  profiles: [],
});

const showDisclaimer = computed(() => {
  return !!selectedContact.value?.id;
});

const contactHasOtherProfiles = computed(() => {
  return selectedContact.value.profiles.length > 1;
});

const otherProfileNames = computed(() => {
  const profileNames = selectedContact.value.profiles
    .map((profile) => profile.name)
    .filter((name) => name !== dbUserData.name);
  if (profileNames.length === 0) {
    return "";
  }
  if (profileNames.length === 1) {
    return profileNames[0];
  }
  return (
    profileNames.slice(0, -1).join(", ") + " and " + profileNames.slice(-1)[0]
  );
});

const associatedContactForm = reactive({
  name: "",
  email: "",
  primary_contact: false,
});

const associatedContactRules = computed(() => ({
  name: { required },
  email: { required, email },
  primary_contact: { boolean: true },
}));

const vac$ = useVuelidate(associatedContactRules, associatedContactForm);

const v$ErrorMessage = (errors: any) => {
  if (!errors.length) return "";

  const error = errors[0];
  switch (error.$validator) {
    case "required":
      return `${capitalize(error.$property)} is required.`;
    case "email":
      return `${capitalize(error.$property)} must be a valid email address.`;
    default:
      return error.$message;
  }
};

const capitalize = (s: string) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const saveAssociatedContact = async () => {
  if (await vac$.value.$validate()) {
    if (!selectedContact.value.id) {
      await addAssociatedContact();
    } else {
      await updateAssociatedContact();
    }
  }
};

const addAssociatedContact = async () => {
  const result = await addContactToUser(userId, {
    name: associatedContactForm.name,
    email: associatedContactForm.email,
    primary_contact: associatedContactForm.primary_contact,
    organization: dbUserData.organization_id,
    profile: userId,
  });
  if ((result as { error: any }).error) {
    if ("error" in result) {
      showToast({ msg: result.error });
    }
  } else {
    showToast({
      severity: "success",
      msg: "Added contact.",
      summary: "Success",
    });
    showContactModal.value = false;

    await setUser();
  }
};

const updateAssociatedContact = async () => {
  if (!selectedContact.value.id) {
    console.log("no selected contact id");
    return;
  }
  const result = await updateAssociatedContactApi(
    userId,
    selectedContact.value.id,
    {
      id: selectedContact.value.id,
      name: associatedContactForm.name,
      email: associatedContactForm.email,
      primary_contact: associatedContactForm.primary_contact,
    }
  );
  if ((result as { error: any }).error) {
    if ("error" in result) {
      showToast({ msg: result.error });
    }
  } else {
    showToast({
      severity: "success",
      msg: "Updated contact.",
      summary: "Success",
    });
    showContactModal.value = false;

    await setUser();
  }
};

const removeAssociatedContact = async (
  contact: AdminProfileAssociatedContact
) => {
  const result = await removeContactFromUser(userId, contact.id);
  if (result?.error) {
    showToast({ msg: result.error });
  } else {
    showToast({
      severity: "success",
      msg: "Removed contact.",
      summary: "Success",
    });
    await setUser();
  }
};

const newAssociatedContact = () => {
  showContactModal.value = true;
  selectedContact.value = {
    email: "",
    name: "",
    id: null,
    primary_contact: false,
    profiles: [],
  };
};

const editAssociatedContact = (contact: AdminProfileAssociatedContact) => {
  showContactModal.value = true;
  selectedContact.value = {
    email: contact.email,
    name: contact.name,
    id: contact.id,
    primary_contact: contact.primary_contact,
    profiles: contact.profiles,
  };
};

const loadAssociatedContacts = async () => {
  const contactsResult = await getAssociatedContacts(userId);
  if (contactsResult && "error" in contactsResult) {
    showToast({ msg: contactsResult.error });
    associatedContacts.value = [];
  } else {
    associatedContacts.value = contactsResult?.results || [];
  }
};

watch(
  () => selectedContact.value,
  () => {
    associatedContactForm.name = selectedContact.value.name;
    associatedContactForm.email = selectedContact.value.email;
    associatedContactForm.primary_contact =
      selectedContact.value.primary_contact || false;
    selectedContact.value.primary_contact;
  }
);

watch(showContactModal, (val) => {
  if (!val) {
    loadAssociatedContacts();
  }
});

const resetPassword = async () => {
  if (!userId) {
    return;
  }

  const newPassword = passwordForm?.password?.trim();
  if (!newPassword) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Password cannot be blank.",
      life: 10000,
    });
    return;
  }

  const result: any = await updateUserProfile(userId, {
    password: newPassword,
  });
  const error = (result as any)?.error ?? "";
  if (error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: error,
      life: 10000,
    });
  } else {
    toast.add({
      severity: "success",
      summary: "Password changed.",
      life: 3000,
    });

    // close dialog
    passwordSetVisible.value = false;
  }
};

interface showToastType {
  severity?: "success" | "info" | "warn" | "error" | undefined;
  summary?: string;
  msg: string;
  life?: number;
}

const toast = useToast();
const showToast = (data: showToastType) => {
  toast.add({
    severity: data.severity ?? "error",
    summary: data?.summary ?? "Error",
    detail: data?.msg,
    life: data.life ?? 10000,
  });
};

let passwordSetVisible = ref(false);

const updateProfileImage = async ({
  assetId,
  asset,
}: {
  assetId?: number;
  asset?: Asset;
}) => {
  if (!userId) {
    form.picture_id = assetId;
    imageData.value = asset?.file;
    return;
  }

  // update profile with assetId
  const result: any = await updateUserProfile(userId, {
    picture_id: assetId,
  });
  const error = (result as any)?.error ?? "";
  if (error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: error,
      life: 10000,
    });
  } else {
    toast.add({
      severity: "success",
      summary: "Profile image updated.",
      life: 3000,
    });

    // load asset into component
    imageData.value = result?.picture?.file;
  }
};

const uploadFailed = async (d: { error: string }) => {
  if (d.error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: d.error,
      life: 10000,
    });
  }
};

const route = useRoute();

const isSubmitting = ref(false);

const confirm = useConfirm();
const confirmDelete = (userId: number) => {
  confirm.require({
    message: "Are you sure you want to delete this user?",
    header: "Delete User",
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    accept: async () => {
      try {
        await deleteUser(userId);
        router.push({
          name: ROUTE_NAME.USER_LIST,
        });
      } catch (e) {}
    },
  });
};

const confirmDeleteUserFromTeam = (userId: number, teamId: number) => {
  confirm.require({
    message: "Are you sure you want to delete this user for this team?",
    header: "Delete User From Team",
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    accept: async () => {
      try {
        const result = await deleteUserFromTeam(userId, teamId);
        if (result?.error) {
          showToast({ msg: result.error });
        } else {
          showToast({
            severity: "success",
            msg: "Deleted user from team.",
            summary: "Success",
          });
          await setUser();
        }
      } catch (e) {}
    },
  });
};

const confirmDeleteAssociatedContact = (
  contact: AdminProfileAssociatedContact
) => {
  confirm.require({
    message: `Are you sure you want to remove ${contact.name} from User ${dbUserData.name} ?`,
    header: "Delete Contact",
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    accept: async () => {
      try {
        await removeAssociatedContact(contact);
      } catch (e) {}
    },
  });
};

const restoreUserThenRedisplay = async (userId: number) => {
  try {
    const result = await restoreUser(userId);
    if ("error" in result) {
      showToast({ msg: result.error });
    } else {
      showToast({
        severity: "success",
        msg: "User restored.",
        summary: "Success",
      });
      await setUser();
    }
  } catch (e) {}
};

const updateCoachingThenRedisplay = async (
  coachId: number,
  formCoach: object
) => {
  try {
    const result = await updateCoach(coachId, formCoach);
    if ("error" in result) {
      showToast({ msg: result.error });
    } else {
      showToast({
        severity: "success",
        msg: "Updated coach.",
        summary: "Success",
      });
      await setUser();
    }
  } catch (e) {}
};

const removeAsCoachThenRedisplay = async (coachId: number) => {
  try {
    const result = await removeAsCoach(coachId);
    if (result?.error) {
      showToast({ msg: result.error });
    } else {
      showToast({
        severity: "success",
        msg: "Removed as coach.",
        summary: "Success",
      });
      await setUser();
    }
  } catch (e) {}
};

const makeACoachThenRedisplay = async (
  userId: number,
  organization_id: number
) => {
  try {
    const result = await makeACoach(userId, organization_id);
    if ("error" in result) {
      showToast({ msg: result.error });
    } else {
      showToast({
        severity: "success",
        msg: "Added as coach. Be sure to add them to a Team.",
        summary: "Success",
        life: 10000,
      });
      await setUser();
    }
  } catch (e) {}

  addMixpanelEvent(EVENT_NAMES.USER.UserMadeCoach);
};

const createUserThenRedisplay = async (form: AdminProfile) => {
  try {
    const result = await createUser(form);

    if ("error" in result) {
      showToast({ msg: result.error });
    } else {
      showToast({
        severity: "success",
        msg: "Created a user.",
        summary: "Success",
      });
      router.push({
        name: ROUTE_NAME.USER_UPDATE,
        params: { id: result?.id },
      });
    }
  } catch (e) {}
  addMixpanelEvent(EVENT_NAMES.USER.UserCreated);
};

const addUserToTeamThenRedisplay = async (teamId: number, userId: number) => {
  try {
    const result = await addUserToTeam(teamId, userId);
    if ("error" in result) {
      showToast({ msg: result.error });
    } else {
      await setUser();

      showToast({
        severity: "success",
        msg: "Added to team.",
        summary: "Success",
      });
    }
  } catch (e) {}
};

let teams = ref<ProfileTeams[]>([]);

let allTeams = ref<{ id: number; name: string }[]>([]);

let isCoach = ref(false);

let coachId = ref(0);

const userId = parseInt(((route.params || {}).id || 0).toString());

let isUserActive = ref(true);

const isCreateForm = computed<boolean>(() => !userId);

let validUserId = ref(true);
type UserFormType = Pick<
  AdminProfile,
  | "name"
  | "is_admin"
  | "email"
  | "organization_id"
  | "can_record_screen"
  | "picture_id"
  | "phone_number"
>;
let form = reactive<UserFormType>({
  name: "",
  is_admin: false,
  email: "",
  organization_id: undefined,
  can_record_screen: false,
  phone_number: "",
  picture_id: undefined,
});

const rules = computed(() => ({
  name: { required },
  email: { required },
  organization_id: { required },
  phone_number: {
    numeric: helpers.withMessage("Numbers only", numeric),
    minLength: minLength(10),
    maxLength: maxLength(10),
  },
}));

const v$ = useVuelidate(rules, form);

let passwordForm = reactive<{ password?: string; password2?: string }>({
  password: undefined,
  password2: undefined,
});

const passwordRules = computed(() => ({
  password: { required, minLength: minLength(6) },
  password2: {
    required,
    sameAs: helpers.withMessage(
      "Both password do not match.",
      sameAs(passwordForm.password)
    ),
  },
}));

const vp$ = useVuelidate(passwordRules, passwordForm);

let addTeamForm = reactive({
  add_to_team: -1,
});

const addTeamFormRules = computed(() => ({
  add_to_team: { required },
}));

const addteamformv$ = useVuelidate(addTeamFormRules, addTeamForm);

let formCoach = reactive({
  snippet: "",
  bio: "",
  is_available: false,
});

const formCoachRules = computed(() => ({}));

const formcoachv$ = useVuelidate(formCoachRules, formCoach);

interface dbuserDataType {
  name: string;
  email: string;
  organization_id: number;
  is_admin: boolean;
  can_record_screen: boolean;
  phone_number?: string;
}

let dbUserData: dbuserDataType = {
  name: "",
  email: "",
  organization_id: -1,
  is_admin: false,
  can_record_screen: false,
  phone_number: undefined,
};

let formChanges = (): { count: number; data: UserFormType } => {
  let changesToMake = {};
  let changeCount = 0;
  Object.keys(form).map((key) => {
    if (form[key] !== dbUserData[key]) {
      changesToMake[key] = form[key];
      changeCount++;
    }
  });
  return {
    count: changeCount,
    data: changesToMake,
  };
};

const setUser = async () => {
  if (!userId) {
    return;
  }

  try {
    const userData = await getUser(userId);
    if ("error" in userData) {
      validUserId.value = false;
      return;
    }

    validUserId.value = true;

    teams.value = userData.teams || [];

    isCoach.value = userData.is_coach || false;

    coachId.value = userData.coach?.id;

    isUserActive.value = userData.is_active || false;

    if (!isCoach.value) {
      await loadAssociatedContacts();
    }

    dbUserData = {
      name: userData.name || "",
      email: userData.email || "",
      organization_id: userData.organization?.id ?? undefined,
      is_admin: userData.is_admin || false,
      // can_record is read-only, can_record_screen is writable
      can_record_screen: userData.can_record || false,
      phone_number: userData?.phone_number,
    };

    Object.keys(dbUserData).map((key) => (form[key] = dbUserData[key]));

    if (userData?.picture?.file) {
      imageData.value = userData.picture.file;
    }

    emit("user", { userName: form?.name });

    // coach
    formCoach.snippet = userData.coach?.snippet ?? "";
    formCoach.bio = userData.coach?.bio ?? "";
    formCoach.is_available = userData.coach?.is_available ?? false;

    const teamsResponse = await getTeams(1000, 0, dbUserData.organization_id);
    if (!("error" in teamsResponse)) {
      const teamList = teamsResponse.results || [];
      allTeams.value = teamList.map((d) => {
        return { id: d.id, name: d.name };
      });
    }
  } catch (e) {
    validUserId.value = false;
  }
};

const updateUserThenRedisplay = async (userId: number, form: UserFormType) => {
  const changes = formChanges();

  const result = await updateUser(userId, changes.data);
  if ("error" in result) {
    showToast({ msg: result.error });
  } else {
    showToast({
      severity: "success",
      msg: "Updated user.",
      summary: "Success",
    });

    emit("user", { userName: form?.name });

    await setUser();
  }

  // Track an event when the user is made an admin
  if (changes?.data.is_admin && dbUserData.is_admin === false) {
    addMixpanelEvent(EVENT_NAMES.USER.UserMadeAdmin);
  }
};

onMounted(async () => {
  await getAllOrganizations();
  await fetchOrganizations();

  if (userId) {
    await setUser();

    addMixpanelEvent(EVENT_NAMES.USER.AdminNavUser);
  } else {
    if (isCreateForm.value && isSingleOrgUser.value && defaultOrg) {
      form.organization_id = defaultOrg.id;
    }
  }
});
</script>

<style>
.user-avatar {
  width: 140px;
  height: 140px;
}

.label-fixed-width {
  width: 200px;
}

.truncate {
  width: 300px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
</style>
@/shared/utils/analytics
