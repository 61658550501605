<script setup lang="ts">
import { computed } from "vue";
import type { PaymentPlan } from "@/api/model";
import { getFormattedCurrency } from "@/shared/utils/helpers";
import { getScheduledPaymentStatusDisplayValue } from "../../offerings/helpers";

const paymentPlan = defineModel<PaymentPlan>("paymentPlan", {
  required: true,
});

const hasPaymentTerms = computed(() => {
  return paymentPlan.value.start_date !== paymentPlan.value.end_date;
});

const pastTransactions = computed(() => {
  return paymentPlan.value.past_transactions;
});

const futureTransactions = computed(() => {
  return paymentPlan.value.future_transactions;
});

const installmentPaymentDayOfMonth = computed(() => {
  const iso8601Day =
    paymentPlan.value.recurring_start_date?.split("-")?.pop() || "";
  const dayOfTheMonth = parseInt(iso8601Day, 10);

  switch (dayOfTheMonth) {
    case 1:
      return "1st";
    case 15:
      return "15th";
    default:
      return dayOfTheMonth;
  }
});

const installmentTermsLength = computed(() => {
  return (
    paymentPlan.value.future_transactions.length +
    paymentPlan.value.past_transactions.length
  );
});

const installmentAmount = computed(() => {
  // TODO: Switch over to amount with fees. Remove the optional checks too once orval updated.
  if (paymentPlan.value.future_transactions.length > 0) {
    return paymentPlan.value.future_transactions[0].amount_with_fees || 0;
  }

  return (
    paymentPlan.value.past_transactions[
      paymentPlan.value.past_transactions.length - 1
    ].amount_with_fees || 0
  );
});
</script>

<template>
  <div>
    <h2 class="text-xl font-bold mb-4">Installments Summary</h2>
  </div>
  <div class="flex flex-col sm:flex-row sm:space-x-2">
    <div class="flex-1 mb-2 sm:mb-0">
      <label class="block text-sm font-medium text-gray-700 mb-1">
        Auto-payment day:
      </label>
      <p class="text-sm text-gray-700 font-light">
        {{ installmentPaymentDayOfMonth }} day of each month
      </p>
    </div>
    <div class="flex-1">
      <label
        for="installmentsTermLength"
        class="block text-sm font-medium text-gray-700 mb-1"
      >
        Term
      </label>
      <p class="text-sm text-gray-700 font-light">
        {{ installmentTermsLength }} Months
      </p>
    </div>
  </div>

  <div class="mt-4">
    <label class="block text-sm font-medium text-gray-700 mb-1">
      Installment Amount
    </label>
    <div v-if="hasPaymentTerms">
      <p class="text-sm text-gray-500">
        {{ getFormattedCurrency(installmentAmount) }}/month
      </p>
    </div>
  </div>

  <label class="block text-sm font-medium text-gray-700 mb-1">
    Payment Schedule
  </label>
  <div class="w-1/2 text-sm border border-gray-200 rounded">
    <div
      v-for="(payment, index) in pastTransactions"
      :key="payment.id"
      class="flex justify-between px-3 py-2 gap-2 border-b border-gray-200 last:border-b-0"
    >
      <div class="font-thin">
        Installment {{ index + 1 }}
        <span
          v-if="payment.status === 'SUCCEEDED' || payment.status === 'REVERSED'"
          class="text-green-600"
        >
          {{ getScheduledPaymentStatusDisplayValue(payment.status) }}
        </span>
        <span
          v-if="payment.status === 'PROCESSING' || payment.status === 'RETRY'"
        >
          {{ getScheduledPaymentStatusDisplayValue(payment.status) }}
        </span>
        <span v-if="payment.status === 'FAILED'" class="text-red-600">
          {{ getScheduledPaymentStatusDisplayValue(payment.status) }}
        </span>
      </div>
      <div class="font-thin">
        {{ payment.scheduled_at }} ({{
          getFormattedCurrency(payment.amount_with_fees)
        }})
      </div>
    </div>
    <div
      v-for="(payment, index) in futureTransactions"
      :key="index"
      class="flex justify-between px-3 py-2 gap-2 border-b border-gray-200 last:border-b-0"
    >
      <div class="font-thin">
        Installment {{ index + 1 + pastTransactions.length }}
      </div>
      <div class="font-thin">
        {{ payment.scheduled_at }} ({{
          getFormattedCurrency(payment.amount_with_fees || 0)
        }})
      </div>
    </div>
  </div>
</template>
