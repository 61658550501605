import { type RouteRecordRaw } from "vue-router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import ViewSubscriptions from "./views/ViewSubscriptions.vue";
import CreateOffering from "./views/CreateOffering.vue";
import EditOffering from "./views/EditOffering.vue";
import ViewOffering from "./views/ViewOffering.vue";
import ViewSubscriberMembership from "./views/ViewSubscriberMembership.vue";
import ViewRegistrant from "./views/ViewRegistrant.vue";
import ViewRegistrations from "./views/ViewRegistrations.vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/payments/memberships",
    name: ROUTE_NAME.PAYMENT_MEMBERSHIPS,
    component: ViewSubscriptions,
  },
  {
    path: "/payments/registrations",
    name: ROUTE_NAME.PAYMENT_REGISTRATIONS,
    component: ViewRegistrations,
  },
  {
    path: "/payments/offerings/create/:offering_type?",
    name: ROUTE_NAME.PAYMENT_OFFERINGS_CREATE,
    component: CreateOffering,
  },
  {
    path: "/payments/offerings/:id/edit",
    name: ROUTE_NAME.PAYMENT_OFFERINGS_EDIT,
    component: EditOffering,
  },
  {
    path: "/payments/registrations/registrant/:id",
    name: ROUTE_NAME.PAYMENT_REGISTRANT_VIEW,
    component: ViewRegistrant,
  },
  {
    path: "/payments/memberships/:id",
    name: ROUTE_NAME.PAYMENT_MEMBERSHIPS_VIEW,
    component: ViewOffering,
  },
  {
    path: "/payments/memberships/membership/:id",
    name: ROUTE_NAME.PAYMENT_MEMBERSHIPS_MEMBER_VIEW,
    component: ViewSubscriberMembership,
  },
  {
    path: "/payments/registrations/:id",
    name: ROUTE_NAME.PAYMENT_REGISTRATIONS_VIEW,
    component: ViewOffering,
  },
];
