/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */

/**
 * * `SCHEDULED` - Scheduled
* `PROCESSING` - Processing
* `SUCCEEDED` - Succeeded
* `FAILED` - Failed
* `RETRY` - Retry
* `REVERSED` - Reversed
 */
export type ScheduledPaymentStatusEnum = typeof ScheduledPaymentStatusEnum[keyof typeof ScheduledPaymentStatusEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScheduledPaymentStatusEnum = {
  SCHEDULED: 'SCHEDULED',
  PROCESSING: 'PROCESSING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  RETRY: 'RETRY',
  REVERSED: 'REVERSED',
} as const;
