/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */

/**
 * * `draft` - Draft
* `published` - Published
* `archived` - Archived
 */
export type OfferingStatus = typeof OfferingStatus[keyof typeof OfferingStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OfferingStatus = {
  draft: 'draft',
  published: 'published',
  archived: 'archived',
} as const;
