<template>
  <div class="flex mb-4">
    <h3 class="ml-4 text-2xl font-bold">
      {{ props.isMembership ? "Memberships" : "Registrations" }}
    </h3>
    <div class="flex justify-end w-full gap-4">
      <a
        v-if="paymentLink"
        href="#"
        class="text-primary-600 hover:text-primary-800 visited:text-primary-600 flex items-center font-medium mr-4"
        @click.prevent="copyUrl(paymentLink)"
      >
        <span>Copy Link</span>
        <i class="pi pi-copy ml-2"></i>
      </a>
      <Button
        label="Create"
        class="px-4"
        @click="
          router.push({
            name: ROUTE_NAME.PAYMENT_OFFERINGS_CREATE,
            query: {
              offering_type: props.isMembership
                ? OfferingTypeEnum.membership
                : OfferingTypeEnum.registration,
            },
          })
        "
      />
    </div>
  </div>
  <AppCard class="mt-6 animate-fadein animate-duration-1000">
    <DataTable
      :value="subscriptions"
      striped-rows
      row-hover
      size="large"
      selection-mode="single"
      @row-click="onRowClick"
    >
      <Column header="Title">
        <template #body="slotProps">
          <div class="text-base">
            <span class="font-bold">{{ slotProps.data.title }}</span
            ><br />
            <span class="font-normal text-md text-gray-400">{{
              getSubtextForTitle(slotProps.data)
            }}</span>
          </div>
        </template>
      </Column>
      <Column header="Amount">
        <template #body="slotProps">
          <div class="text-base">
            <span class="">{{
              getFormattedCurrency(slotProps.data.amount)
            }}</span
            ><br />
            <span
              v-if="props.isMembership"
              class="font-normal text-md text-gray-400"
              >{{
                getFrequencyDisplayValue(slotProps.data.payment_frequency)
              }}</span
            >
          </div>
        </template>
      </Column>
      <Column header="Participants">
        <template #body="slotProps">
          <div class="text-base">
            <span class="">{{
              getParticipantsCountDisplay(slotProps.data)
            }}</span>
          </div>
        </template>
      </Column>
      <Column v-if="props.isMembership" header="Recurring Rev.">
        <template #body="slotProps">
          <div class="text-base">
            <span class="">{{
              slotProps.data.recurring_revenue === null
                ? "-"
                : getFormattedCurrency(slotProps.data.recurring_revenue)
            }}</span>
          </div>
        </template>
      </Column>
      <Column header="Enrollment">
        <template #body="slotProps">
          <div class="text-base">
            <span
              v-if="slotProps.data.enrollment_status"
              :class="getStatusColor(slotProps.data.enrollment_status)"
              >{{ capitalize(slotProps.data.enrollment_status) }}</span
            >
          </div>
        </template>
      </Column>
      <Column header="Share">
        <template #body="slotProps">
          <div class="text-base">
            <button
              v-if="showShareLink(slotProps.data)"
              type="button"
              class="text-blue-600 hover:text-blue-800 inline-flex items-center cursor-pointer"
              @click="copyUrl(slotProps.data.share_url)"
            >
              <i class="ml-2 pi pi-copy" />
            </button>
          </div>
        </template>
      </Column>
    </DataTable>
    <Paginator
      :always-show="true"
      :rows="pageSize"
      :total-records="totalRecords"
      template="
            FirstPageLink
            PrevPageLink
            CurrentPageReport
            NextPageLink
            LastPageLink
            RowsPerPageDropdown"
      current-page-report-template="Showing {first} to {last} of {totalRecords}"
      @page="onPageChange"
    />
  </AppCard>
</template>

<script setup lang="ts">
import AppCard from "@/shared/components/AppCard.vue";
import DataTable, { type DataTableRowClickEvent } from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import router from "@/router";
import { useRoute } from "vue-router";
import Paginator, { type PageState } from "primevue/paginator";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { onMounted, computed } from "vue";
import { OfferingStatus, type Offering, OfferingTypeEnum } from "@/api/model";
import { useToast } from "primevue/usetoast";
import { getFormattedCurrency } from "@/shared/utils/helpers";
import { capitalize } from "lodash";
import { getFormattedDate, getFrequencyDisplayValue } from "../helpers";
import { useOfferings } from "../composables/useOfferings";
import { useOrganizationStoreV2 } from "@/modules/organizations/stores/organizationsStoreV2";

const toast = useToast();

const {
  paginatedOfferings,
  fetchOfferings,
  currentPage,
  pageSize,
  totalRecords,
} = useOfferings();

const subscriptions = computed(() => paginatedOfferings.value?.results);

const props = defineProps<{
  isMembership: boolean;
}>();

const offeringType = computed(() => {
  if (props.isMembership) {
    return "payment-memberships";
  }

  return "payment-registrations";
});

const organizationStore = useOrganizationStoreV2();
const organization = computed(() => organizationStore.defaultOrganization);

const paymentLink = computed(() => {
  if (!organization.value?.payment_links) return null;
  return props.isMembership
    ? organization.value.payment_links.memberships_index_url
    : organization.value.payment_links.registrations_index_url;
});

const copyUrl = async (url: string) => {
  try {
    await navigator.clipboard.writeText(url);
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "URL copied to clipboard",
      life: 5000,
    });
  } catch (err) {
    console.error("Failed to copy URL: ", err);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to copy URL",
      life: 5000,
    });
  }
};

const onPageChange = (event: PageState) => {
  currentPage.value = event.page;
  fetchOfferings(offeringType.value as string, { archived: false });
};

const onRowClick = (event: DataTableRowClickEvent) => {
  const routeName =
    event.data.offering_type === OfferingTypeEnum.registration
      ? ROUTE_NAME.PAYMENT_REGISTRATIONS_VIEW
      : ROUTE_NAME.PAYMENT_MEMBERSHIPS_VIEW;

  router.push({
    name: routeName,
    params: { id: event.data.id },
  });
};

const getStatusColor = (status: string) => {
  switch (status) {
    case "open":
      return "text-green-600";
    case "closed":
      return "text-red-600";
    default:
      return "";
  }
};

const showShareLink = (offering: Offering) => {
  return offering.status === OfferingStatus.published;
};

const getParticipantsCountDisplay = (offering: Offering) => {
  const effectiveCount = offering.subscriber_count ?? 0;

  if (offering.participant_limit) {
    return `${effectiveCount}/${offering.participant_limit}`;
  }

  return offering.subscriber_count;
};

const getSubtextForTitle = (offering: Offering) => {
  if (offering.registration_start_date && offering.registration_end_date) {
    if (offering.registration_start_date === offering.registration_end_date) {
      return getFormattedDate(offering.registration_start_date);
    }

    return (
      getFormattedDate(offering.registration_start_date) +
      " - " +
      getFormattedDate(offering.registration_end_date)
    );
  }

  if (offering.registration_start_date) {
    return "Starts " + getFormattedDate(offering.registration_start_date);
  }

  // Return a non-breaking space to provide consistent height for all rows
  return "\u200b";
};

onMounted(() => {
  fetchOfferings(offeringType.value as string, { archived: false });
});
</script>
