/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */

/**
 * * `membership` - Membership
* `registration` - Registration
 */
export type OfferingTypeEnum = typeof OfferingTypeEnum[keyof typeof OfferingTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OfferingTypeEnum = {
  membership: 'membership',
  registration: 'registration',
} as const;
