<template>
  <AppCard>
    <div class="space-y-4">
      <div class="flex justify-between items-start">
        <div>
          <h1 class="text-2xl font-bold inline-block">{{ offering?.title }}</h1>
          <div
            v-if="isRegistrationOffering && hasRegistrationDates"
            class="text-sm text-gray-500 mt-1"
          >
            {{ formattedRegistrationDates }}
          </div>
        </div>

        <div v-if="isMembershipOffering" class="text-right">
          <div class="text-lg font-semibold text-gray-500">
            {{ formattedAmount }}
          </div>
          <div class="text-sm text-gray-500">
            {{ formattedFrequency }}
          </div>
        </div>

        <div v-else-if="isRegistrationOffering" class="text-right">
          <div class="text-lg font-semibold text-gray-500">
            {{ formattedAmount }}
          </div>
          <div v-if="shouldShowInstallments" class="text-sm text-gray-500">
            or {{ formattedInstallmentAmount }} for
            {{ offering.installments_term_length }} mo.
          </div>
        </div>

        <span v-else class="text-lg font-semibold text-gray-500 ml-4">
          {{ formattedAmount }} {{ formattedFrequency }}
        </span>
      </div>
    </div>
    <div class="mt-4 space-y-4">
      <p v-if="hasDescription">
        <span>{{
          isExpanded ? offering.description : truncatedDescription
        }}</span>
        <button
          v-if="shouldShowReadMore"
          class="text-primary-600 hover:text-primary-700 block mt-2 text-sm font-medium"
          @click="isExpanded = !isExpanded"
        >
          {{ isExpanded ? "See less" : "See more" }}
        </button>
      </p>
      <template v-if="shouldShowTags">
        <div class="bg-gray-50 px-4 py-1 mt-4 mx-0 rounded-md">
          <div
            class="flex flex-wrap gap-2 text-sm text-surface-600 items-center"
          >
            <div
              v-if="hasVisibleCategory"
              class="rounded px-2 py-1 flex items-center"
            >
              <i class="pi pi-tag mr-1"></i>
              {{ capitalizedCategory }}
            </div>
            <div v-if="shouldShowDividerAfterCategory" class="text-surface-400">
              |
            </div>

            <div
              v-if="shouldShowEnrollmentDateInfo"
              class="rounded px-2 py-1 flex items-center"
            >
              <i class="pi pi-calendar mr-1"></i>
              {{ formattedEnrollmentDateInfo }}
            </div>
            <div
              v-if="shouldShowDividerAfterEnrollmentInfo"
              class="text-surface-400"
            >
              |
            </div>

            <div
              v-if="hasVisibleAgeRestriction"
              class="rounded px-2 py-1 flex items-center"
            >
              <i class="pi pi-user mr-1"></i>
              {{ formattedAgeRestriction }}
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-if="hasSubscriptionDocuments" class="mt-10 space-y-4">
      <p v-if="requiresDocumentAcceptance" class="text-md text-gray-500">
        Members are required to review and agree to the following document(s).
      </p>
      <p v-else class="text-md text-gray-500">Membership document(s)</p>
      <ul class="flex flex-row gap-2">
        <li v-for="document in offering?.offering_documents" :key="document.id">
          <SubscriptionDocument :document="document" />
        </li>
      </ul>
    </div>
  </AppCard>
  <AppCard class="mt-4">
    <div
      class="flex flex-col md:grid md:gap-4 mb-4 space-y-4 md:space-y-0"
      :class="isMembershipOffering ? 'md:grid-cols-4' : 'md:grid-cols-3'"
    >
      <div class="p-4 rounded-lg shadow-md border-surface-200 border-[1px]">
        <div class="text-surface-500 dark:text-surface-300 text-sm">
          Participants
        </div>
        <div class="font-semibold text-xl">
          {{ formattedParticipantCount }}
        </div>
        <div
          class="text-xs text-surface-400 font-normal mt-1 bg-gray-50 rounded p-1"
        >
          +{{ lastThirtyDaysMembershipCount }} in last 30 days
        </div>
      </div>
      <div
        v-if="isMembershipOffering"
        class="p-4 rounded-lg shadow-md border-surface-200 border-[1px]"
      >
        <div class="text-surface-500 dark:text-surface-300 text-sm">
          Recurring Revenue
        </div>
        <div class="font-semibold text-xl">{{ formattedRecurringRevenue }}</div>
        <div
          class="text-xs text-surface-400 font-normal mt-1 bg-gray-50 rounded p-1"
        >
          {{ formattedFrequency }}
        </div>
      </div>
      <div class="p-4 rounded-lg shadow-md border-surface-200 border-[1px]">
        <div class="text-surface-500 dark:text-surface-300 text-sm">
          Enrollment
        </div>
        <div class="font-semibold text-xl">
          <span :class="enrollmentStatusClass">
            {{ capitalize(offering?.enrollment_status ?? "") }}
          </span>
          <div
            v-if="hasEnrollmentStatusUpdateDate"
            class="text-xs text-surface-400 font-normal mt-1 bg-gray-50 rounded p-1"
          >
            Since {{ formattedEnrollmentDate }}
          </div>
        </div>
      </div>
      <div class="p-4 rounded-lg shadow-md border-surface-200 border-[1px]">
        <div class="text-surface-500 dark:text-surface-300 text-sm">
          Visibility
        </div>
        <div class="font-semibold text-xl">
          {{ capitalize(offering?.is_public ? "Public" : "Private") }}
        </div>
        <div
          class="text-xs text-surface-400 font-normal mt-1 bg-gray-50 rounded p-1"
        >
          {{ formattedVisibilityDate }}
        </div>
      </div>
    </div>
  </AppCard>

  <AppCard class="mt-4">
    <div class="flex items-center gap-4 mb-4">
      <h2 class="text-xl font-bold m-0">Participants</h2>
      <div class="relative">
        <InputText
          v-model="searchQuery"
          placeholder="Search..."
          class="w-full sm:w-auto pr-8"
        />
        <i
          class="pi pi-search absolute right-2 top-1/2 -translate-y-1/2 text-gray-500"
        />
      </div>
    </div>
    <DataTable
      :value="filteredParticipants"
      :rows="10"
      :sortable="true"
      striped-rows
      row-hover
      selection-mode="single"
      @row-click="handleRowClick"
    >
      <Column
        field="subscription_participant.name"
        header="Participant Name"
        sortable
      />
      <Column
        field="subscription_participant.email"
        header="Participant Email"
        sortable
      />
      <Column field="start_at" header="Sign-up Date" sortable>
        <template #body="{ data }">
          {{ getFormattedDate(data.created_at) }}
        </template>
      </Column>
      <Column field="cancelled_at" header="Enrollment Status">
        <template #body="{ data }">
          <span :class="getEnrollmentStatusClass(data.cancelled_at)">
            {{ getEnrollmentStatusText(data.cancelled_at) }}
          </span>
        </template>
      </Column>
      <Column field="last_payment_status" header="Payment Status">
        <template #body="{ data }">
          <span :class="getPaymentStatusClass(data.last_payment_status)">
            {{ data.last_payment_status || "Unknown" }}
          </span>
        </template>
      </Column>
    </DataTable>
    <div
      v-if="filteredParticipants?.length === 0"
      class="flex items-center justify-center mt-4 py-8"
    >
      <p class="text-gray-500">Sign-ups will appear here</p>
    </div>
  </AppCard>
</template>

<script setup lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import type { Offering } from "@/api/model";
import type { AdminV1PaymentsOfferingsSubscriberMembershipsListResult } from "@/api/admin-payments-subscriber-memberships/admin-payments-subscriber-memberships";
import { getFormattedCurrency } from "@/shared/utils/helpers";
import SubscriptionDocument from "./SubscriptionDocument.vue";
import { computed, ref } from "vue";
import {
  EnrollmentStatusEnum,
  OfferingTypeEnum,
  LastPaymentStatusEnum,
} from "@/api/model";
import { capitalize } from "lodash";
import InputText from "primevue/inputtext";
import { getFrequencyDisplayValue, getFormattedDate } from "../helpers";
import { useRouter } from "vue-router";
import { ROUTE_NAME } from "@/shared/constants/routes";

const props = defineProps<{
  offering: Offering;
  memberships?: AdminV1PaymentsOfferingsSubscriberMembershipsListResult;
  lastThirtyDaysMembershipCount: number;
}>();

const isExpanded = ref(false);
const MAX_LENGTH = 250;

const truncatedDescription = computed(() => {
  if (!props.offering?.description) return "";
  return props.offering.description.length > MAX_LENGTH
    ? props.offering.description.slice(0, MAX_LENGTH) + "..."
    : props.offering.description;
});

const shouldShowReadMore = computed(
  () =>
    props.offering?.description &&
    props.offering.description.length > MAX_LENGTH
);

const formattedRecurringRevenue = computed(() => {
  return getFormattedCurrency(props.offering?.recurring_revenue ?? 0);
});

const formattedEnrollmentDate = computed(() => {
  return props.offering?.latest_enrollment_status_update_at
    ? getFormattedDate(props.offering.latest_enrollment_status_update_at)
    : "";
});

const searchQuery = ref("");

const filteredParticipants = computed(() => {
  if (!searchQuery.value || !props.memberships?.results) {
    return props.memberships?.results;
  }

  const query = searchQuery.value.toLowerCase();
  return props.memberships.results.filter(
    (membership) =>
      membership.subscription_participant.name.toLowerCase().includes(query) ||
      membership.subscription_participant.email.toLowerCase().includes(query)
  );
});

const router = useRouter();

const handleRowClick = (event: { data: any }) => {
  const routeName = isRegistrationOffering.value
    ? ROUTE_NAME.PAYMENT_REGISTRANT_VIEW
    : ROUTE_NAME.PAYMENT_MEMBERSHIPS_MEMBER_VIEW;

  router.push({
    name: routeName,
    params: { id: event.data.id },
  });
};

const formattedParticipantCount = computed(() => {
  const count = props.offering?.subscriber_count ?? 0;
  if (props.offering?.participant_limit) {
    return `${count} / ${props.offering.participant_limit}`;
  }
  return count.toString();
});

const hasVisibleCategory = computed(() => !!props.offering?.offering_category);

const hasVisibleEnrollmentInfo = computed(
  () => hasEnrollmentDateInfo.value && !!formattedEnrollmentDateInfo.value
);

const hasVisibleAgeRestriction = computed(() => hasAgeRestriction.value);

const shouldShowTags = computed(() => {
  return !!(
    hasVisibleCategory.value ||
    hasVisibleEnrollmentInfo.value ||
    hasVisibleAgeRestriction.value
  );
});

const capitalizedCategory = computed(() => {
  return props.offering?.offering_category
    ? capitalize(props.offering.offering_category.replace(/_/g, " "))
    : "";
});

const hasEnrollmentDateInfo = computed(() => {
  return !!(
    props.offering?.enrollment_open_date ||
    props.offering?.enrollment_close_date
  );
});

const formattedEnrollmentDateInfo = computed(() => {
  const now = new Date();
  const openDate = props.offering?.enrollment_open_date
    ? new Date(props.offering.enrollment_open_date)
    : null;
  const closeDate = props.offering?.enrollment_close_date
    ? new Date(props.offering.enrollment_close_date)
    : null;

  if (openDate && props.offering?.enrollment_open_date && openDate > now) {
    return `Enrollment opens ${getFormattedDate(props.offering.enrollment_open_date)}`;
  }

  if (closeDate && props.offering?.enrollment_close_date && closeDate > now) {
    return `Enrollment closes ${getFormattedDate(props.offering.enrollment_close_date)}`;
  }

  if (closeDate && props.offering?.enrollment_close_date && closeDate <= now) {
    return `Enrollment closed on ${getFormattedDate(props.offering.enrollment_close_date)}`;
  }

  return "";
});

const hasAgeRestriction = computed(() => {
  return !!(
    props.offering?.min_participant_age || props.offering?.max_participant_age
  );
});

const formattedAgeRestriction = computed(() => {
  const min = props.offering?.min_participant_age;
  const max = props.offering?.max_participant_age;

  if (min && max) {
    return `Ages ${min} - ${max}`;
  } else if (min) {
    return `Ages ${min}+`;
  } else if (max) {
    return `Ages ${max} and under`;
  }

  return "";
});

const formattedAmount = computed(() => {
  return getFormattedCurrency(props.offering?.amount ?? 0);
});

const formattedFrequency = computed(() => {
  return getFrequencyDisplayValue(props.offering?.payment_frequency);
});

const shouldShowInstallments = computed(() => {
  return !!(
    props.offering?.installments_enabled &&
    props.offering?.installments_term_length &&
    props.offering?.installments_term_length > 0
  );
});

const formattedInstallmentAmount = computed(() => {
  if (!shouldShowInstallments.value) {
    return "";
  }

  const monthlyAmount =
    props.offering!.amount / props.offering!.installments_term_length!;
  return getFormattedCurrency(monthlyAmount);
});

const isMembershipOffering = computed(
  () => props.offering?.offering_type === OfferingTypeEnum.membership
);

const isRegistrationOffering = computed(
  () => props.offering?.offering_type === OfferingTypeEnum.registration
);

const hasDescription = computed(() => !!props.offering?.description);

const hasSubscriptionDocuments = computed(
  () => (props.offering?.offering_documents?.length ?? 0) > 0
);

const requiresDocumentAcceptance = computed(
  () => !!props.offering?.documents_require_acceptance
);

const enrollmentStatusClass = computed(() =>
  props.offering?.enrollment_status === EnrollmentStatusEnum.open
    ? "text-green-600"
    : "text-red-600"
);

const hasEnrollmentStatusUpdateDate = computed(
  () => !!props.offering?.latest_enrollment_status_update_at
);

const shouldShowDividerAfterCategory = computed(
  () =>
    hasVisibleCategory.value &&
    (hasVisibleEnrollmentInfo.value || hasVisibleAgeRestriction.value)
);

const shouldShowEnrollmentDateInfo = computed(
  () => hasEnrollmentDateInfo.value && !!formattedEnrollmentDateInfo.value
);

const shouldShowDividerAfterEnrollmentInfo = computed(
  () => shouldShowEnrollmentDateInfo.value && hasVisibleAgeRestriction.value
);

const formattedVisibilityDate = computed(() => {
  if (props.offering?.latest_visibility_update_at) {
    return `Since ${getFormattedDate(props.offering.latest_visibility_update_at)}`;
  } else if (props.offering?.created_at) {
    return `Since ${getFormattedDate(props.offering.created_at)}`;
  }
  return "";
});

const getPaymentStatusClass = (status: string | null) => {
  if (!status) return "";

  return status === LastPaymentStatusEnum.Paid ||
    status === LastPaymentStatusEnum.Partially_Paid ||
    status === LastPaymentStatusEnum.None
    ? "text-green-600"
    : "text-red-600";
};

const getEnrollmentStatusClass = (canceledAt: string | null) => {
  return canceledAt === null ? "text-green-600" : "text-red-600";
};

const getEnrollmentStatusText = (canceledAt: string | null) => {
  return canceledAt === null ? "Active" : "Canceled";
};

const hasRegistrationDates = computed(() => {
  return !!(
    props.offering?.registration_start_date ||
    props.offering?.registration_end_date
  );
});

const formattedRegistrationDates = computed(() => {
  const startDate = props.offering?.registration_start_date
    ? getFormattedDate(props.offering.registration_start_date)
    : null;
  const endDate = props.offering?.registration_end_date
    ? getFormattedDate(props.offering.registration_end_date)
    : null;

  if (
    startDate &&
    endDate &&
    props.offering?.registration_start_date ===
      props.offering?.registration_end_date
  ) {
    return startDate;
  } else if (startDate && endDate) {
    return `${startDate} - ${endDate}`;
  } else if (startDate) {
    return `Starts ${startDate}`;
  }

  return "";
});
</script>
