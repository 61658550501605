import { ref, computed } from "vue";
import type { Offering } from "@/api/model";
import { EnrollmentStatusEnum } from "@/api/model";
import type { AdminV1PaymentsOfferingsSubscriberMembershipsListResult } from "@/api/admin-payments-subscriber-memberships/admin-payments-subscriber-memberships";
import { getOffering, getOfferingMemberships, updateOffering } from "../api";
import {
  adminV1PaymentsOfferingsCloseEnrollmentCreate,
  adminV1PaymentsOfferingsOpenEnrollmentCreate,
} from "@/api/admin-payments-offerings/admin-payments-offerings";

export function useOffering() {
  const offering = ref<Offering | null>(null);
  const memberships = ref<
    AdminV1PaymentsOfferingsSubscriberMembershipsListResult | undefined
  >(undefined);
  const fetchOfferingError = ref<string | null>(null);
  const fetchMembershipsError = ref<string | null>(null);
  const updateError = ref<string | null>(null);

  const lastThirtyDaysMembershipCount = computed(() => {
    if (!memberships.value?.results) return 0;
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    return memberships.value.results.filter(
      (membership) => new Date(membership.created_at) >= thirtyDaysAgo
    ).length;
  });

  async function fetchOffering(id: string) {
    const result = await getOffering(id);
    if ("error" in result) {
      fetchOfferingError.value = result.error;
    } else {
      offering.value = result;
    }
  }

  async function fetchMemberships(id: string) {
    const result = await getOfferingMemberships(id);
    if ("error" in result) {
      fetchMembershipsError.value = result.error;
    } else {
      memberships.value = result;
    }
  }

  async function publishOffering(id: string) {
    updateError.value = null;
    const result = await updateOffering(id, { type: "publish" });
    if ("error" in result) {
      updateError.value = result.error;
      return false;
    }
    offering.value = result;
    return true;
  }

  async function archiveOffering(id: string) {
    updateError.value = null;
    const result = await updateOffering(id, { type: "archive" });
    if ("error" in result) {
      updateError.value = result.error;
      return false;
    }
    offering.value = result;
    return true;
  }

  async function updateVisibility(id: string, isPublic: boolean) {
    updateError.value = null;
    const result = await updateOffering(id, {
      type: "is_public",
      isPublic,
    });
    if ("error" in result) {
      updateError.value = result.error;
      return false;
    }
    offering.value = result;
    return true;
  }

  async function closeEnrollment(id: string, o: Offering) {
    updateError.value = null;
    const closeResult = await adminV1PaymentsOfferingsCloseEnrollmentCreate(
      id,
      o
    );
    if ("error" in closeResult) {
      updateError.value = "An error occurred";
      return false;
    }

    const result = await getOffering(id);
    if ("error" in result) {
      fetchOfferingError.value = result.error;
    } else {
      offering.value = result;
    }
    return true;
  }

  async function openEnrollment(id: string, o: Offering) {
    updateError.value = null;
    const openResult = await adminV1PaymentsOfferingsOpenEnrollmentCreate(
      id,
      o
    );
    if ("error" in openResult) {
      updateError.value = "An error occurred";
      return false;
    }

    const result = await getOffering(id);
    if ("error" in result) {
      fetchOfferingError.value = result.error;
    } else {
      offering.value = result;
    }
    return true;
  }

  return {
    offering,
    memberships,
    fetchOffering,
    fetchMemberships,
    fetchOfferingError,
    fetchMembershipsError,
    updateError,
    lastThirtyDaysMembershipCount,
    publishOffering,
    archiveOffering,
    closeEnrollment,
    openEnrollment,
    updateVisibility,
  };
}
