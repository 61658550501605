/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  OfferingStatistics,
  SubscriberMembership
} from '.././model'
import adminV1PaymentsOfferingsStatisticsRetrieveMutator from '../../shared/services/axios-client';
import adminV1PaymentsSubscriberMembershipsRefundCreateMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const adminV1PaymentsOfferingsStatisticsRetrieve = (
    id: string,
 options?: SecondParameter<typeof adminV1PaymentsOfferingsStatisticsRetrieveMutator>,) => {
      return adminV1PaymentsOfferingsStatisticsRetrieveMutator<OfferingStatistics>(
      {url: `/api/admin/v1/payments/offerings/${id}/statistics/`, method: 'GET'
    },
      options);
    }
  export const adminV1PaymentsSubscriberMembershipsRefundCreate = (
    id: string,
    subscriberMembership: NonReadonly<SubscriberMembership>,
 options?: SecondParameter<typeof adminV1PaymentsSubscriberMembershipsRefundCreateMutator>,) => {
      return adminV1PaymentsSubscriberMembershipsRefundCreateMutator<SubscriberMembership>(
      {url: `/api/admin/v1/payments/subscriber-memberships/${id}/refund`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: subscriberMembership
    },
      options);
    }
  export type AdminV1PaymentsOfferingsStatisticsRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsOfferingsStatisticsRetrieve>>>
export type AdminV1PaymentsSubscriberMembershipsRefundCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsSubscriberMembershipsRefundCreate>>>
