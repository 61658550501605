import {
  adminV1PaymentsInvoicesList,
  adminV1PaymentsInvoicesCreate,
  adminV1PaymentsInvoicesRetrieve,
  adminV1PaymentsInvoicesUpdate,
  adminV1PaymentsInvoicesPayWithCashCreate,
} from "@/api/admin-payments-invoices/admin-payments-invoices";

import type {
  AdminV1PaymentsInvoicesListParams,
  PaginatedInvoiceListList,
  CreateInvoice,
  UpdateInvoice,
} from "@/api/model";

type ApiError = {
  error: string;
};

export const getInvoices = async (
  params: AdminV1PaymentsInvoicesListParams
): Promise<PaginatedInvoiceListList | ApiError> => {
  try {
    const results = await adminV1PaymentsInvoicesList(params);

    return results;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getInvoice = async (id: string) => {
  try {
    const result = await adminV1PaymentsInvoicesRetrieve(id);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const createInvoice = async (
  params: CreateInvoice
): Promise<CreateInvoice | ApiError> => {
  try {
    const result = await adminV1PaymentsInvoicesCreate(params);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const updateInvoice = async (
  id: string,
  params: UpdateInvoice
): Promise<UpdateInvoice | ApiError> => {
  try {
    const result = await adminV1PaymentsInvoicesUpdate(id, params);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const markInvoiceAsPaid = async (id: string) => {
  try {
    const result = await adminV1PaymentsInvoicesPayWithCashCreate(id);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};
