<template>
  <Dialog
    v-model:visible="showPlayerDialog"
    modal
    header="Add Players"
    :style="{ width: '500px' }"
    :breakpoints="{ '960px': '90vw', '641px': '95vw' }"
  >
    <MultiSelectList
      :items="props.players"
      v-model:selectedItems="dirtySelectedRecipients"
      searchPlaceholder="Search players"
    />
    <div class="flex justify-between items-center mt-4">
      <span>{{ dirtySelectedRecipients.length }} Players Selected</span>
      <Button
        label="Confirm"
        class="p-button-primary"
        @click="confirmSelection"
      />
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import MultiSelectList from "@/shared/components/MultiSelectList.vue";

import type { Recipient, SelectPlayerDialogProps } from "@/types";

const props = defineProps<SelectPlayerDialogProps>();
const selectedRecipients = defineModel<Recipient[]>("selectedRecipients", {
  required: true,
});
const showPlayerDialog = defineModel<boolean>("showPlayerDialog", {
  required: true,
});

const dirtySelectedRecipients = ref<Recipient[]>([]);

watch(
  selectedRecipients,
  (newValue) => {
    dirtySelectedRecipients.value = [...newValue];
  },
  { immediate: true }
);

watch(showPlayerDialog, (newValue) => {
  if (newValue) {
    dirtySelectedRecipients.value = [...selectedRecipients.value];
  }
});

const confirmSelection = () => {
  selectedRecipients.value = [...dirtySelectedRecipients.value];
  showPlayerDialog.value = false;
};
</script>
