<template>
  <BaseLayout
    :title="`View ${offeringType}`"
    :breadcrumb="[
      {
        label: offeringTypePlural,
        to: {
          name: getBreadcrumbRouteName,
        },
      },
      {
        label: offering?.title,
      },
    ]"
  >
    <div class="flex justify-end mb-4 gap-2">
      <a
        v-if="offering?.share_url"
        href="#"
        class="text-primary-600 hover:text-primary-800 visited:text-primary-600 flex items-center font-medium mr-4"
        :class="{ 'opacity-50 cursor-not-allowed': !offering?.published }"
        @click.prevent="offering?.published && copyUrl(offering.share_url)"
      >
        <span>Copy Link</span>
        <i class="pi pi-copy ml-2"></i>
      </a>
      <ActionsMenu :menu-items="menuItems" :dangerous-action="archiveAction" />
    </div>
    <div>
      <OfferingDetails
        v-if="offering"
        :offering="offering"
        :memberships="memberships"
        :last-thirty-days-membership-count="lastThirtyDaysMembershipCount"
      />
      <div v-else class="text-center p-4 text-gray-500">
        Membership plan not found.
      </div>
    </div>
    <ConfirmDialog group="enrollment">
      <template #message="slotProps">
        <div class="flex flex-col gap-4">
          <p class="whitespace-pre-line">{{ slotProps.message.message }}</p>
        </div>
      </template>
    </ConfirmDialog>
  </BaseLayout>
</template>

<script setup lang="ts">
import BaseLayout from "@/shared/components/BaseLayout.vue";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { useRoute } from "vue-router";
import router from "@/router";
import { onMounted, computed, ref } from "vue";
import Button from "primevue/button";
import OfferingDetails from "@/modules/offerings/components/OfferingDetails.vue";
import { useConfirm } from "primevue/useconfirm";
import ActionsMenu from "@/shared/components/ActionsMenu.vue";
import { useToast } from "primevue/usetoast";
import { EnrollmentStatusEnum, OfferingTypeEnum } from "@/api/model";
import ConfirmDialog from "primevue/confirmdialog";
import { useOffering } from "../composables/useOffering";
import { capitalize } from "lodash";
import { addMixpanelEvent, EVENT_NAMES } from "@/shared/utils/analytics";

const {
  offering,
  memberships,
  fetchOffering,
  fetchMemberships,
  lastThirtyDaysMembershipCount,
  publishOffering,
  archiveOffering,
  closeEnrollment,
  openEnrollment,
  updateVisibility,
  updateError,
} = useOffering();

const route = useRoute();
const onClickEdit = () => {
  router.push({
    name: ROUTE_NAME.PAYMENT_OFFERINGS_EDIT,
    params: { id: offering.value?.id },
    query: {
      title: offering.value?.title,
      offering_type: offeringType.value,
    },
  });
};

const offeringId = route.params.id.toString();

const offeringTypePlural = computed(() => {
  return offering.value?.offering_type === OfferingTypeEnum.registration
    ? "Registrations"
    : "Memberships";
});

const confirm = useConfirm();
const toast = useToast();
const offeringType = computed(() => {
  return offering.value?.offering_type
    ? capitalize(offering.value?.offering_type)
    : "Offering";
});

const getBreadcrumbRouteName = computed(() => {
  return offering.value?.offering_type === OfferingTypeEnum.registration
    ? ROUTE_NAME.PAYMENT_REGISTRATIONS
    : ROUTE_NAME.PAYMENT_MEMBERSHIPS;
});

const ENROLLMENT_MESSAGES = {
  close: {
    title: "Close Enrollment",
    message:
      "Are you sure you want to close enrollment for this membership?\n\nThis membership will no longer be available for new members to enroll. Current members will not be affected.",
    confirmButton: "Close Enrollment",
  },
  open: {
    title: "Open Enrollment",
    message:
      "Are you sure you want to open enrollment for this membership?\n\nThis will allow new members to enroll.",
    confirmButton: "Open Enrollment",
  },
};

const PUBLISH_MESSAGE = {
  title: "Publish Membership",
  message:
    "Are you sure you would like to Publish this Membership?\n\nThis will make the membership available for signups at the sharable link.",
  confirmButton: "Publish",
};

const onClickPublish = () => {
  confirm.require({
    group: "enrollment",
    message: PUBLISH_MESSAGE.message,
    header: PUBLISH_MESSAGE.title,
    acceptLabel: PUBLISH_MESSAGE.confirmButton,
    rejectLabel: "Cancel",
    accept: async () => {
      const success = await publishOffering(offeringId);
      if (success) {
        toast.add({
          severity: "success",
          summary: "Success",
          detail: "Membership published successfully",
          life: 3000,
        });
      } else if (updateError.value) {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: updateError.value,
          life: 3000,
        });
      }
    },
  });
};

const onClickEnrollment = () => {
  const isOpen =
    offering.value?.enrollment_status === EnrollmentStatusEnum.open;
  const dialogConfig = isOpen
    ? ENROLLMENT_MESSAGES.close
    : ENROLLMENT_MESSAGES.open;

  confirm.require({
    group: "enrollment",
    message: dialogConfig.message,
    header: dialogConfig.title,
    acceptLabel: dialogConfig.confirmButton,
    rejectLabel: "Cancel",
    accept: async () => {
      if (!offering.value) {
        return;
      }

      const success = isOpen
        ? await closeEnrollment(offeringId, offering.value)
        : await openEnrollment(offeringId, offering.value);

      if (success) {
        const offeringType = offering.value.offering_type;
        addMixpanelEvent(
          isOpen
            ? EVENT_NAMES.OFFERINGS.CloseEnrollment
            : EVENT_NAMES.OFFERINGS.OpenEnrollment,
          {
            offeringType: offeringType,
          }
        );

        toast.add({
          severity: "success",
          summary: "Success",
          detail: `Enrollment ${isOpen ? "closed" : "opened"} successfully`,
          life: 3000,
        });
      } else if (updateError.value) {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: updateError.value,
          life: 3000,
        });
      }
    },
  });
};

const onClickVisibility = () => {
  const isPublic = offering.value?.is_public;
  confirm.require({
    group: "enrollment",
    message: `Are you sure you want to make this ${offeringType.value} ${isPublic ? "private" : "public"}?`,
    header: `Make ${isPublic ? "Private" : "Public"}`,
    acceptLabel: `Make ${isPublic ? "Private" : "Public"}`,
    rejectLabel: "Cancel",
    accept: async () => {
      const success = await updateVisibility(offeringId, !isPublic);
      if (success) {
        addMixpanelEvent(
          isPublic
            ? EVENT_NAMES.OFFERINGS.MakePrivate
            : EVENT_NAMES.OFFERINGS.MakePublic,
          {
            offeringType: offeringType.value,
          }
        );

        toast.add({
          severity: "success",
          summary: "Success",
          detail: `${offeringType.value} is now ${isPublic ? "private" : "public"}`,
          life: 3000,
        });
      } else if (updateError.value) {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: updateError.value,
          life: 3000,
        });
      }
    },
  });
};

const archiveAction = computed(() => ({
  label: "Archive",
  icon: "pi pi-trash",
  dangerous: true as const,
  disabled: (offering.value?.subscriber_count ?? 0) > 0,
  command: () => {
    const typeLabel = capitalize(offering.value?.offering_type || "offering");

    confirm.require({
      message: `Are you sure you want to archive this ${typeLabel.toLowerCase()}?`,
      header: `Confirm Archive ${typeLabel}`,
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        const success = await archiveOffering(offeringId);
        if (success) {
          toast.add({
            severity: "success",
            summary: "Success",
            detail: `${typeLabel} archived successfully`,
            life: 3000,
          });

          const routeName =
            offering.value?.offering_type === OfferingTypeEnum.registration
              ? ROUTE_NAME.PAYMENT_REGISTRATIONS
              : ROUTE_NAME.PAYMENT_MEMBERSHIPS;

          router.push({ name: routeName });
        } else if (updateError.value) {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: updateError.value,
            life: 3000,
          });
        }
      },
    });
  },
}));

const menuItems = computed(() => [
  {
    label: "Publish",
    icon: "pi pi-send",
    disabled: offering.value?.published,
    command: onClickPublish,
  },
  {
    label:
      offering.value?.enrollment_status === EnrollmentStatusEnum.open
        ? "Close Enrollment"
        : "Open Enrollment",
    icon:
      offering.value?.enrollment_status === EnrollmentStatusEnum.open
        ? "pi pi-lock"
        : "pi pi-lock-open",
    command: onClickEnrollment,
  },
  {
    label: offering.value?.is_public ? "Make Private" : "Make Public",
    icon: offering.value?.is_public ? "pi pi-eye-slash" : "pi pi-eye",
    command: onClickVisibility,
  },
  {
    label: "Edit",
    icon: "pi pi-pencil",
    command: onClickEdit,
  },
]);

const copyUrl = async (url: string) => {
  try {
    await navigator.clipboard.writeText(url);
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "URL copied to clipboard",
      life: 5000,
    });
  } catch (err) {
    console.error("Failed to copy URL: ", err);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to copy URL",
      life: 5000,
    });
  }
};

onMounted(async () => {
  await Promise.all([fetchOffering(offeringId), fetchMemberships(offeringId)]);
});
</script>
