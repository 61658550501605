import {
  ChatStatus as NewStatusEnum,
  type AdminChat,
  type AdminChatMessage,
} from "../../../api/model";

export const Labels = {
  SUBMITTED: "Submitted",
  ATHLETE_SUBMITTED: "Athlete Submitted",
  COACH_REJECTED: "Coach Rejected",
  COACH_RESPONDED: "Coach Responded",
  ADMIN_SUBMITTED_PREFIX: "Admin Submitted",
  COACH_SUBMITTED_PREFIX: "Coach Submitted",
};

type StatusMapped = { label: string; filter: string[] };

export const StatusMapped: Record<keyof typeof NewStatusEnum, StatusMapped> = {
  Pending: {
    label: Labels.SUBMITTED,
    filter: [NewStatusEnum.Pending],
  },
  Rejected: {
    label: Labels.COACH_REJECTED,
    filter: [NewStatusEnum.Rejected],
  },
  Resolved: {
    label: Labels.COACH_RESPONDED,
    filter: [NewStatusEnum.Resolved],
  },
  Started: {
    label: Labels.COACH_RESPONDED,
    filter: [NewStatusEnum.Started, NewStatusEnum.Resolved],
  },
};

export const StatusOptions = Object.entries(StatusMapped).map(
  ([key, { label }]) => ({
    name: label,
    id: key,
  })
);

export function getStatusTitle(
  chat: Pick<Partial<AdminChat>, "status" | "submitted_by">
) {
  const { status, submitted_by } = chat;

  if (typeof status === "undefined") {
    throw new Error("Status is undefined");
  }

  if (status === NewStatusEnum.Pending && submitted_by?.is_coach) {
    const coachName = String(submitted_by.name);
    return {
      label: `${Labels.COACH_SUBMITTED_PREFIX} (${coachName})`,
      filter: [NewStatusEnum.Pending],
    };
  } else if (status === NewStatusEnum.Pending && submitted_by?.is_admin) {
    const adminName = String(submitted_by.name);
    return {
      label: `${Labels.ADMIN_SUBMITTED_PREFIX} (${adminName})`,
      filter: [NewStatusEnum.Pending],
    };
  } else if (status === NewStatusEnum.Pending) {
    return {
      label: Labels.ATHLETE_SUBMITTED,
      filter: [NewStatusEnum.Pending],
    };
  } else if (status === NewStatusEnum.Resolved && submitted_by?.is_coach) {
    const coachName = String(submitted_by.name);
    return {
      label: `${Labels.COACH_SUBMITTED_PREFIX} (${coachName})`,
      filter: [NewStatusEnum.Resolved],
    };
  }

  return StatusMapped[status];
}

export function getStatusTitleBasedOnSender(
  chat: Pick<Partial<AdminChat>, "status" | "submitted_by">,
  chatMessage: AdminChatMessage,
  firstChatMessageId: number
) {
  const { status, submitted_by } = chat;
  const { id: chatMessageId } = chatMessage;
  const { is_coach: isCoach } = chatMessage?.sender;

  if (typeof status === "undefined") {
    throw new Error("Status is undefined");
  }

  if (submitted_by?.is_admin && chatMessageId === firstChatMessageId) {
    const adminName = String(submitted_by.name);
    return `${Labels.ADMIN_SUBMITTED_PREFIX} (${adminName})`;
  } else if (!isCoach) {
    return Labels.ATHLETE_SUBMITTED;
  }

  return StatusMapped[status].label;
}
