<template>
  <FixedActionBar
    button-type="submit"
    :is-loading="isSaving"
    :show-delete="true"
    :show-update="false"
    @delete="onDelete"
  >
    <AppButton class="mr-2" @click="createPublishedSubscription()">
      <span class="p-button-label">Publish</span>
    </AppButton>
    <AppButton class="mr-2" @click="submitForm()">
      <span class="p-button-label">Save Draft</span>
    </AppButton>
  </FixedActionBar>

  <AppCard class="mt-6 animate-fadein animate-duration-1000 text-surface-600">
    <div class="flex">
      <div class="flex flex-col w-[50%]">
        <label for="offering-title">Title *</label>
        <InputText
          id="offering-title"
          v-model="form.title"
          type="text"
          placeholder="Title"
          :class="{
            'p-invalid border-red-500': v$.title.$error,
          }"
          class="mt-1 block w-full rounded-md shadow-sm border-gray-300"
        />
        <small v-if="v$ErrorMessage(v$.title.$errors)" class="text-red-500">
          {{ v$ErrorMessage(v$.title.$errors) }}
        </small>
      </div>
    </div>
    <div class="flex">
      <div class="flex flex-col w-[80%] my-5">
        <label for="offering-description">Description</label>

        <Textarea
          id="offering-description"
          v-model="form.description"
          type="text"
          class="mt-1 block w-full rounded-md shadow-sm border-gray-300"
          :rows="7"
          :auto-resize="true"
          :show-clear-icon="true"
        />
      </div>
    </div>
  </AppCard>

  <AppCard class="mt-4">
    <div class="mb-4 w-[50%]">
      <h4 class="block mb-2 font-medium">Payment Type</h4>
      <div class="flex flex-col gap-4 md:flex-row">
        <div class="flex items-center p-4 rounded-md w-full md:w-1/2">
          <RadioButton
            v-model="form.offering_type"
            input-id="registration_option"
            value="registration"
            class="mr-2"
          />
          <div>
            <label for="registration_option" class="font-normal"
              >Simple Registration</label
            >
            <div class="text-gray-500 text-sm">
              One-time payment or installments
            </div>
          </div>
        </div>
        <div class="flex items-center p-4 rounded-md w-full md:w-1/2">
          <RadioButton
            v-model="form.offering_type"
            input-id="membership_option"
            value="membership"
            class="mr-2"
          />
          <div>
            <label for="membership_option" class="font-normal"
              >Membership Enrollment</label
            >
            <div class="text-gray-500 text-sm">
              Automatic recurring payments
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-4 p-4 border rounded-md p-8 my-6">
      <div class="flex flex-col gap-4 md:flex-row">
        <div class="flex flex-col w-full md:w-1/2">
          <label for="amount" class="block mb-2 font-medium"
            >Payment Amount *</label
          >
          <IconField>
            <InputIcon class="pi pi-dollar" />
            <InputNumber
              v-model="form.amount"
              input-id="amount"
              placeholder="Amount"
              type="number"
              mode="currency"
              currency="USD"
              locale="en-US"
              class="w-full"
            />
            <small
              v-if="v$ErrorMessage(v$.amount.$errors)"
              class="text-red-500"
            >
              {{ v$ErrorMessage(v$.amount.$errors) }}
            </small>
          </IconField>
        </div>

        <div v-if="formType === 'membership'" class="flex flex-col w-full">
          <label for="payment_frequency" class="block mb-2 font-medium"
            >Payment Frequency *</label
          >
          <Select
            v-model="form.payment_frequency"
            input-id="payment_frequency"
            :options="frequencyOptions"
            option-label="label"
            option-value="value"
            placeholder="Select Frequency"
            class="w-full"
          />
          <small
            v-if="v$ErrorMessage(v$.payment_frequency.$errors)"
            class="text-red-500"
          >
            {{ v$ErrorMessage(v$.payment_frequency.$errors) }}
          </small>
        </div>
      </div>
      <div v-if="formType === 'registration'">
        <div class="flex items-start mt-4">
          <Checkbox
            v-model="form.installments_enabled"
            input-id="installments_enabled"
            :binary="true"
            class="mr-2"
          />
          <div class="flex flex-col ml-3">
            <label for="installments_enabled">Allow monthly installments</label>
            <span class="text-sm text-gray-500"
              >Set to your preferred schedule</span
            >
          </div>
        </div>
        <div v-if="installmentsEnabled" class="flex flex-col sm:flex-row mt-4">
          <div class="flex-1 mb-4 sm:mb-0 mr-4">
            <label
              for="installments_payment_day"
              class="block text-sm font-medium text-gray-700 mb-1"
            >
              Auto-payment day:
            </label>
            <Select
              v-model="form.installments_payment_day"
              input-id="installments_payment_day"
              :options="paymentDays"
              size="large"
              option-label="label"
              option-value="value"
              placeholder="Select"
              class="w-full"
            />
            <small
              v-if="v$ErrorMessage(v$.installments_payment_day.$errors)"
              class="block text-red-500"
            >
              {{ v$ErrorMessage(v$.installments_payment_day.$errors) }}
            </small>
          </div>
          <div class="flex-1">
            <label
              for="installments_term_length"
              class="block text-sm font-medium text-gray-700 mb-1"
            >
              Term
            </label>
            <div class="flex">
              <InputNumber
                v-model="form.installments_term_length"
                input-id="installments_term_length"
                type="number"
                input-class="rounded-r-none"
                :min="2"
              />
              <InputGroupAddon
                class="bg-gray-200 border border-l-0 rounded-r px-3 flex items-center"
              >
                Months
              </InputGroupAddon>
            </div>
            <small
              v-if="v$ErrorMessage(v$.installments_term_length.$errors)"
              class="block text-red-500"
            >
              {{ v$ErrorMessage(v$.installments_term_length.$errors) }}
            </small>
          </div>
        </div>
        <div v-if="installmentsEnabled">
          <div class="block text-sm font-medium text-gray-700 mb-1 mt-2">
            Installment Amount
          </div>
          <div
            v-if="
              form.installments_payment_day && form.installments_term_length
            "
          >
            <p class="text-sm text-gray-500">{{ paymentPerTerm }}</p>
          </div>
          <div v-else>
            <p class="text-sm text-gray-500">Enter payment day and term</p>
          </div>
        </div>
      </div>
    </div>
  </AppCard>

  <AppCard class="mt-4">
    <div class="card p-4 mb-4 w-[50%]">
      <h2 class="text-xl font-semibold mb-4">Details</h2>

      <div
        v-if="formType === 'registration'"
        class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4"
      >
        <div>
          <label for="offering_category" class="block mb-2 font-medium"
            >Category *</label
          >
          <Select
            v-model="form.offering_category"
            input-id="offering_category"
            :options="offeringCategoryOptions"
            option-label="label"
            option-value="value"
            placeholder="Select Category"
            class="w-full"
          />
          <small
            v-if="v$ErrorMessage(v$.offering_category.$errors)"
            class="text-red-500"
          >
            {{ v$ErrorMessage(v$.offering_category.$errors) }}
          </small>
        </div>
      </div>
      <div
        v-if="formType === 'registration'"
        class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4"
      >
        <div>
          <label for="registration_start_date" class="block mb-2 font-medium"
            >Start Date</label
          >
          <DatePicker
            v-model="form.registration_start_date"
            input-id="registration_start_date"
            :min-date="new Date()"
            placeholder="Select date"
            date-format="mm/dd/yy"
            class="w-full"
            show-icon
          />
          <small
            v-if="v$ErrorMessage(v$.registration_start_date.$errors)"
            class="text-red-500"
          >
            {{ v$ErrorMessage(v$.registration_start_date.$errors) }}
          </small>
        </div>
        <div>
          <label for="registration_end_date" class="block mb-2 font-medium"
            >End Date</label
          >
          <DatePicker
            v-model="form.registration_end_date"
            input-id="registration_end_date"
            :min-date="new Date()"
            placeholder="Select date"
            date-format="mm/dd/yy"
            class="w-full"
            show-icon
          />
          <small
            v-if="v$ErrorMessage(v$.registration_end_date.$errors)"
            class="text-red-500"
          >
            {{ v$ErrorMessage(v$.registration_end_date.$errors) }}
          </small>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div>
          <label for="min_participant_age" class="block mb-2 font-medium"
            >Min. Participant Age</label
          >
          <Select
            v-model="form.min_participant_age"
            input-id="min_participant_age"
            :options="ageOptions"
            option-label="name"
            option-value="code"
            placeholder="None"
            class="w-full"
          />
        </div>
        <div>
          <label for="max_participant_age" class="block mb-2 font-medium"
            >Max. Participant Age</label
          >
          <Select
            v-model="form.max_participant_age"
            input-id="max_participant_age"
            :options="ageOptions"
            option-label="name"
            option-value="code"
            placeholder="None"
            class="w-full"
          />
          <small
            v-if="v$ErrorMessage(v$.max_participant_age.$errors)"
            class="text-red-500"
          >
            {{ v$ErrorMessage(v$.max_participant_age.$errors) }}
          </small>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div>
          <label for="enrollment_open_date" class="block mb-2 font-medium"
            >Auto-open enrollment on</label
          >
          <DatePicker
            v-model="form.enrollment_open_date"
            input-id="enrollment_open_date"
            :min-date="new Date()"
            placeholder="Select date"
            date-format="mm/dd/yy"
            class="w-full"
            show-icon
          />
          <small
            v-if="v$ErrorMessage(v$.enrollment_open_date.$errors)"
            class="text-red-500"
          >
            {{ v$ErrorMessage(v$.enrollment_open_date.$errors) }}
          </small>
        </div>
        <div>
          <label for="enrollment_close_date" class="block mb-2 font-medium"
            >Auto-close enrollment on</label
          >
          <DatePicker
            v-model="form.enrollment_close_date"
            input-id="enrollment_close_date"
            :min-date="new Date()"
            placeholder="Select date"
            date-format="mm/dd/yy"
            class="w-full"
            show-icon
          />
          <small
            v-if="v$ErrorMessage(v$.enrollment_close_date.$errors)"
            class="text-red-500"
          >
            {{ v$ErrorMessage(v$.enrollment_close_date.$errors) }}
          </small>
        </div>
      </div>

      <div class="mb-4">
        <label for="participant_limit" class="block mb-2 font-medium"
          >Participant Limit</label
        >
        <div class="flex items-center gap-4">
          <InputText
            v-model="form.participant_limit"
            input-id="participant_limit"
            type="number"
            placeholder="0"
            class="w-48"
            :disabled="participantLimitDisabled"
            min="1"
          />
          <div class="flex items-center">
            <Checkbox
              v-model="form.no_participant_limit"
              input-id="no_participant_limit"
              :binary="true"
              class="mr-2"
            />
            <label for="no_participant_limit">No Limit</label>
          </div>
        </div>
        <small
          v-if="v$ErrorMessage(v$.participant_limit.$errors)"
          class="text-red-500"
        >
          {{ v$ErrorMessage(v$.participant_limit.$errors) }}
        </small>
      </div>

      <div class="mb-4">
        <label for="is_public" class="block mb-2 font-medium">Visibility</label>
        <div class="flex gap-4">
          <div class="flex items-center">
            <RadioButton
              v-model="form.is_public"
              input-id="is_public"
              :value="true"
              class="mr-2"
            />
            <label for="is_public">Public</label>
          </div>
          <div class="flex items-center">
            <RadioButton
              v-model="form.is_public"
              input-id="is_private"
              :value="false"
              class="mr-2"
            />
            <label for="is_private">Private</label>
          </div>
        </div>
      </div>
    </div>
  </AppCard>

  <AppCard class="mt-4">
    <div class="text-xl my-4">Documents</div>
    <div class="flex mb-4">
      <div class="flex w-full items-center">
        <Checkbox
          v-model="form.documents_require_acceptance"
          input-id="documents_require_acceptance"
          :binary="true"
          class="mr-2"
          :class="{
            'p-invalid border-red-500': v$.documents_require_acceptance.$error,
          }"
        />
        <label for="documents_require_acceptance"
          >Require members to review and agree to documents.</label
        >
      </div>
    </div>
    <div>
      <small
        v-if="v$ErrorMessage(v$.documents_require_acceptance.$errors)"
        class="text-red-500"
      >
        {{ v$ErrorMessage(v$.documents_require_acceptance.$errors) }}
      </small>
    </div>

    <div class="flex flex-col w-full">
      <div class="flex flex-wrap gap-4 mb-4">
        <div
          v-for="asset in adminAssets"
          :key="asset?.id"
          class="flex flex-col"
        >
          <AssetThumbnail :asset-prop="asset" class="mt-2" box-size="140px" />
          <div class="flex">
            <Tag
              value="Delete"
              class="cursor-pointer mt-6"
              severity="danger"
              @click="() => deleteAttachmentConfirm(asset?.id)"
            >
              Delete
            </Tag>
          </div>
        </div>
      </div>

      <div class="w-[50%]">
        <FileDropzone
          :organization-id="organizationId"
          :only-documents-allowed="true"
          :clear-previous="false"
          :max-file-size="MAX_FILE_SIZE"
          @completed="onDocumentsUploaded"
          @failed="uploadFailed"
        />
      </div>
    </div>
  </AppCard>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { v$ErrorMessage } from "@/shared/utils/helpers";
import { helpers, minValue, required, requiredIf } from "@vuelidate/validators";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import RadioButton from "primevue/radiobutton";
import DatePicker from "primevue/datepicker";
import Select from "primevue/select";
import InputNumber from "primevue/inputnumber";
import InputGroupAddon from "primevue/inputgroupaddon";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import Textarea from "primevue/textarea";
import Tag from "primevue/tag";
import { PaymentFrequencyEnum } from "@/api/model/paymentFrequencyEnum";
import AppCard from "@/shared/components/AppCard.vue";
import FixedActionBar from "@/shared/components/FixedActionBar.vue";
import { useOrganizations } from "@/composables/useOrganizations";
import { getFrequencyDisplayValue } from "@/modules/offerings/helpers";
import FileDropzone from "@/shared/components/FileDropzone.vue";
import { createOffering } from "../api";
import {
  AssetType,
  EnrollmentStatusEnum,
  type AdminAsset,
  type Offering,
} from "@/api/model";
import { OfferingCategoryEnum } from "@/api/model/offeringCategoryEnum";
import router from "@/router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import AssetThumbnail from "@/shared/components/AssetThumbnail.vue";
import { useToastStore } from "@/shared/stores/toastStore";
import { addMixpanelEvent, EVENT_NAMES } from "@/shared/utils/analytics";
import { useRoute } from "vue-router";
import { getFormattedCurrency } from "@/shared/utils/helpers";

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const toast = useToast();
const toastStore = useToastStore();
const confirm = useConfirm();
const isSaving = ref(false);
const { defaultOrganization } = useOrganizations();
const organizationId = ref<number>(0);
const adminAssets = ref<AdminAsset[]>([]);

watch(defaultOrganization, (value) => {
  organizationId.value = value?.id || 0;
});

type SubscriptionFrequencyEnum = Exclude<
  PaymentFrequencyEnum,
  (typeof PaymentFrequencyEnum)["one-time"]
>;
const route = useRoute();

const paymentDays = [
  {
    label: "1st",
    value: 1,
  },
  {
    label: "15th",
    value: 15,
  },
];

const frequencyOptions = Object.values(PaymentFrequencyEnum)
  .filter(
    (v): v is SubscriptionFrequencyEnum =>
      v !== PaymentFrequencyEnum["one-time"]
  )
  .map((v) => ({
    label: getFrequencyDisplayValue(v),
    value: v,
  }));

// Define a custom form type with all the fields we need
type OfferingFormType = {
  id: string;
  title: string;
  amount: number;
  description: string;
  payment_frequency: SubscriptionFrequencyEnum;
  documents_require_acceptance: boolean;
  offering_documents: Array<{ asset_id: number }>;
  enrollment_status: EnrollmentStatusEnum;
  installments_enabled?: boolean;
  installments_payment_day?: number | null;
  installments_term_length?: number | null;
  published: boolean;
  is_public: boolean;
  offering_category?: OfferingCategoryEnum;
  offering_type: string;
  min_participant_age: number | null;
  max_participant_age: number | null;
  enrollment_open_date?: Date | null;
  enrollment_close_date?: Date | null;
  participant_limit: number | null;
  registration_start_date?: Date | null;
  no_participant_limit: boolean;
  registration_end_date?: Date | null;
};

// Age options for dropdowns
const ageOptions = Array.from({ length: 100 }, (_, i) => {
  if (i === 0) {
    return {
      name: "None",
      code: null,
    };
  }
  return {
    name: String(i),
    code: i,
  };
});

const offeringCategoryOptions = Object.values(OfferingCategoryEnum).map(
  (v) => ({
    label: v.charAt(0).toUpperCase() + v.slice(1),
    value: v,
  })
);

const emptySubscriptionPlanForm: OfferingFormType = {
  id: "0",
  title: "",
  amount: 0,
  description: "",
  payment_frequency: PaymentFrequencyEnum.monthly as SubscriptionFrequencyEnum,
  documents_require_acceptance: false,
  offering_documents: [],
  enrollment_status: EnrollmentStatusEnum.closed,
  installments_enabled: false,
  installments_payment_day: null,
  installments_term_length: null,
  published: false,
  is_public: true,
  offering_category: OfferingCategoryEnum.team,
  offering_type: "registration",
  min_participant_age: null,
  max_participant_age: null,
  enrollment_open_date: null,
  enrollment_close_date: null,
  participant_limit: 0,
  no_participant_limit: true,
  registration_start_date: null,
  registration_end_date: null,
};

const form = ref<OfferingFormType>(emptySubscriptionPlanForm);

const formType = ref<string>("registration");

const participantLimitDisabled = computed(() => {
  return form.value.no_participant_limit;
});

const offeringType = computed(() => {
  return form.value.offering_type;
});

const installmentsEnabled = computed(() => {
  return form.value.installments_enabled || false;
});

const paymentPerTerm = computed(() => {
  if (
    form.value.installments_payment_day &&
    form.value.installments_term_length &&
    form.value.amount
  ) {
    return `${getFormattedCurrency(form.value.amount / form.value.installments_term_length)}/month`;
  }
  return null;
});

watch(offeringType, (value) => {
  formType.value = value;
});

const rules = computed(() => ({
  title: { required: helpers.withMessage("Title is required", required) },
  amount: {
    minValue: helpers.withMessage("Cost must exceed $0.00", minValue(0.01)),
    required: helpers.withMessage("Amount is required", required),
  },
  payment_frequency: {
    required: helpers.withMessage(
      "Frequency is required",
      requiredIf(offeringType.value === "membership")
    ),
  },
  installments_payment_day: {
    required: helpers.withMessage(
      "Installment payment day is required",
      requiredIf(
        installmentsEnabled.value && offeringType.value === "registration"
      )
    ),
  },
  installments_term_length: {
    required: helpers.withMessage(
      "Installment term length is required",
      requiredIf(
        installmentsEnabled.value && offeringType.value === "registration"
      )
    ),
  },
  offering_category: {
    required: helpers.withMessage(
      "Category is required",
      requiredIf(offeringType.value === "registration")
    ),
  },
  max_participant_age: {
    minValue: helpers.withMessage(
      "Maximum participant age must be greater or equal to minimum participant age",
      minValue(form.value.min_participant_age || -1)
    ),
  },
  documents_require_acceptance: {
    custom: helpers.withMessage(
      "At least one document must be uploaded when requiring acceptance",
      function (val: boolean) {
        // If we don't require acceptance, then the rule passes
        if (!val) {
          return true;
        }
        // If we do require acceptance, check if there are documents uploaded
        return form.value.offering_documents.length > 0;
      }
    ),
  },
  registration_start_date: {
    minValue: helpers.withMessage(
      "Registration start date must be in the future",
      function (val: string) {
        if (!val) {
          return true;
        }
        return new Date(val) > new Date();
      }
    ),
  },
  registration_end_date: {
    minValue: helpers.withMessage(
      "Registration end date must be at or after registration start date",
      function (val: string) {
        if (!val || !form.value.registration_start_date) {
          return true;
        }
        return new Date(val) >= new Date(form.value.registration_start_date);
      }
    ),
  },
  enrollment_open_date: {
    minValue: helpers.withMessage(
      "Enrollment open date must be before registration start date",
      function (val: string) {
        if (!val || !form.value.registration_start_date) {
          return true;
        }
        return new Date(val) < new Date(form.value.registration_start_date);
      }
    ),
  },
  participant_limit: {
    minValue: helpers.withMessage(
      "Participant limit cannot be zero",
      function (val: string) {
        if (form.value.no_participant_limit) return true;
        return Number(val) > 0;
      }
    ),
  },
  enrollment_close_date: {
    minValue: helpers.withMessage(
      "Enrollment close date must be at or after enrollment open date and before registration start date",
      function (val: string) {
        if (!val) {
          return true;
        }
        const openDate = form.value.enrollment_open_date;
        const startDate = form.value.registration_start_date;

        // If no start date, only validate against open date
        if (!startDate) {
          if (!openDate) return true;
          return new Date(val) >= new Date(openDate);
        }

        // If both dates exist, validate against both
        if (openDate) {
          return (
            new Date(val) >= new Date(openDate) &&
            new Date(val) < new Date(startDate)
          );
        }

        // If only start date exists, validate against it
        return new Date(val) < new Date(startDate);
      }
    ),
  },
}));

const validationState = computed(() => ({
  title: form.value.title,
  amount: form.value.amount,
  payment_frequency: form.value.payment_frequency,
  installments_payment_day: form.value.installments_payment_day,
  installments_term_length: form.value.installments_term_length,
  offering_category: form.value.offering_category,
  max_participant_age: form.value.max_participant_age,
  registration_start_date: form.value.registration_start_date,
  registration_end_date: form.value.registration_end_date,
  enrollment_open_date: form.value.enrollment_open_date,
  enrollment_close_date: form.value.enrollment_close_date,
  documents_require_acceptance: form.value.documents_require_acceptance,
  offering_documents: form.value.offering_documents,
  participant_limit: form.value.participant_limit,
}));

const v$ = useVuelidate(rules, validationState);

const submitForm = async () => {
  const offeringTypeDisplay =
    form.value.offering_type === "registration" ? "Registration" : "Membership";

  await v$.value.$validate();
  if (v$.value.$invalid) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: `${offeringTypeDisplay} fields are not valid.`,
      life: 3000,
    });
    return;
  }
  try {
    // Convert form to Offering type before sending to API
    const paymentFreq =
      form.value.offering_type === "registration"
        ? PaymentFrequencyEnum["one-time"]
        : form.value.payment_frequency;

    const participantLimit = form.value.no_participant_limit
      ? null
      : form.value.participant_limit;

    const offeringData = {
      id: form.value.id,
      title: form.value.title,
      amount: form.value.amount,
      registration_start_date: form.value.registration_start_date,
      registration_end_date: form.value.registration_end_date,
      enrollment_open_date: form.value.enrollment_open_date,
      enrollment_close_date: form.value.enrollment_close_date,
      offering_category: form.value.offering_category,
      min_participant_age: form.value.min_participant_age,
      max_participant_age: form.value.max_participant_age,
      participant_limit: participantLimit,
      installments_enabled: form.value.installments_enabled,
      installments_payment_day: form.value.installments_payment_day,
      installments_term_length: form.value.installments_term_length,
      is_public: form.value.is_public,
      description: form.value.description || "",
      payment_frequency: paymentFreq,
      documents_require_acceptance: form.value.documents_require_acceptance,
      offering_documents: form.value.offering_documents,
      published: form.value.published,
      offering_type: form.value.offering_type,
    } as Offering;

    const result = await createOffering(offeringData, offeringType.value);

    if ("error" in result) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: result.error,
        life: 3000,
      });
      return;
    }

    // Only proceed with success actions if there was no error
    setToastMessage();
    setMixpanelEvent();
    redirectToList(offeringType.value);
  } catch (e) {
    // Handle any unexpected errors
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "An unexpected error occurred",
      life: 3000,
    });
  }
};

const uploadFailed = async () => {
  toast.add({
    severity: "error",
    summary: "Error",
    detail: "Failed to upload documents",
    life: 3000,
  });
};

const onDocumentsUploaded = async (assetIds: number[]) => {
  const newAssets = assetIds.map((id) => ({
    id: id,
    type: AssetType.document,
  }));
  adminAssets.value = [...adminAssets.value, ...newAssets];

  const assets = assetIds.map((id) => ({ asset_id: id }));
  form.value.offering_documents = [...form.value.offering_documents, ...assets];

  toast.add({
    severity: "success",
    summary: "Success",
    detail: "Documents uploaded successfully",
    life: 3000,
  });
};

const deleteAttachmentConfirm = (file_id: number) => {
  confirm.require({
    message: "Are you sure you want to delete this attachment?",
    header: "Delete Attachment",
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    accept: async () => {
      try {
        removeAttachment(file_id);
      } catch (e) {
        console.error(e);
      }
    },
  });
};

const removeAttachment = (file_id: number) => {
  form.value.offering_documents = form.value.offering_documents.filter(
    (doc) => doc.asset_id !== file_id
  );
  adminAssets.value = adminAssets.value.filter((asset) => asset.id !== file_id);
};

const createPublishedSubscription = async () => {
  form.value.published = true;
  submitForm();
};

const deleteAndRedirect = async (offeringType: string) => {
  if (offeringType === "registration") {
    router.push({
      name: ROUTE_NAME.PAYMENT_REGISTRATIONS,
    });
  } else {
    router.push({
      name: ROUTE_NAME.PAYMENT_MEMBERSHIPS,
    });
  }
};
const onDelete = () => {
  if (form.value.id !== "0") {
    onConfirmDelete();
  } else {
    const offeringType = form.value.offering_type;
    redirectToList(offeringType);
  }
};

const redirectToList = (offeringType: string) => {
  if (offeringType === "registration") {
    router.push({
      name: ROUTE_NAME.PAYMENT_REGISTRATIONS,
    });
  } else {
    router.push({
      name: ROUTE_NAME.PAYMENT_MEMBERSHIPS,
    });
  }
};

const setToastMessage = () => {
  const offeringTypeDisplay =
    form.value.offering_type === "registration" ? "Registration" : "Membership";

  if (form.value.published) {
    toastStore.setPendingToast({
      severity: "success",
      summary: "Success",
      detail: `${offeringTypeDisplay} published successfully`,
      life: 3000,
    });
  } else {
    toastStore.setPendingToast({
      severity: "success",
      summary: "Success",
      detail: `${offeringTypeDisplay} saved successfully`,
      life: 3000,
    });
  }
};

const setMixpanelEvent = () => {
  const offeringStatus = form.value.published ? "Published" : "Draft";
  const offeringType = form.value.offering_type;
  addMixpanelEvent(EVENT_NAMES.OFFERINGS.OfferingCreated, {
    offeringStatus: offeringStatus,
    offeringType: offeringType,
  });
};

const onConfirmDelete = async () => {
  const offeringTypeDisplay =
    form.value.offering_type === "registration" ? "registration" : "membership";

  confirm.require({
    message: `Are you sure you want to delete this ${offeringTypeDisplay}?`,
    header: `Delete ${offeringTypeDisplay}`,
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    accept: async () => {
      try {
        await deleteAndRedirect(form.value.offering_type);
      } catch (e) {}
    },
  });
};

onMounted(() => {
  organizationId.value = defaultOrganization.value?.id || 0;

  // Set the offering type based on the query parameter
  if (route.query.offering_type) {
    form.value.offering_type = route.query.offering_type as string;
    formType.value = form.value.offering_type;
  }
});
</script>
