import {
  adminV1PaymentsOfferingsCreate,
  adminV1PaymentsOfferingsList,
  adminV1PaymentsOfferingsRetrieve,
  adminV1PaymentsOfferingsPartialUpdate,
} from "@/api/admin-payments-offerings/admin-payments-offerings";

import {
  adminV1PaymentsOfferingsSubscriberMembershipsList,
  adminV1PaymentsSubscriberMembershipsRetrieve,
  type AdminV1PaymentsOfferingsSubscriberMembershipsListResult,
  adminV1PaymentsSubscriberMembershipsDestroy,
} from "@/api/admin-payments-subscriber-memberships/admin-payments-subscriber-memberships";
import { adminV1PaymentsSubscriberMembershipsRefundCreate } from "@/api/admin/admin";
import type {
  PaginatedOfferingList,
  SubscriptionDocument,
  Offering,
  EnrollmentStatusEnum,
  OfferingUpdate,
  SubscriberMembership,
  PaginatedScheduledPaymentList,
  ScheduledPayment,
  OfferingTypeEnum,
} from "@/api/model";
import type { AdminV1PaymentsOfferingsListParams } from "@/api/model/adminV1PaymentsOfferingsListParams";
import type { NonReadonly, SubscriptionPlanForm } from "@/types";

export const getOfferings = async (
  params: AdminV1PaymentsOfferingsListParams
): Promise<PaginatedOfferingList | { error: string }> => {
  try {
    const response: PaginatedOfferingList =
      await adminV1PaymentsOfferingsList(params);
    return response;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getOffering = async (
  id: string
): Promise<Offering | { error: string }> => {
  try {
    const response: Offering = await adminV1PaymentsOfferingsRetrieve(id);
    return response;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const createOffering = async (
  offering: Offering,
  offeringType: string
): Promise<Offering> => {
  const docs: SubscriptionDocument[] = (offering.offering_documents || []).map(
    (doc) => ({
      asset_id: doc.asset_id,
      asset: null as any,
      created_at: "",
      id: "",
      updated_at: "",
    })
  );

  const offeringData: NonReadonly<Offering> = {
    amount: offering.amount,
    description: offering.description,
    documents_require_acceptance: offering.documents_require_acceptance,
    payment_frequency: offering.payment_frequency,
    is_public: offering.is_public,
    published: offering.published,
    offering_documents: docs,
    title: offering.title,
    offering_type: offeringType as OfferingTypeEnum,
  };

  if (offering.participant_limit) {
    offeringData.participant_limit = offering.participant_limit;
  }

  if (offeringType === "registration") {
    if (offering.installments_enabled) {
      offeringData.installments_enabled = offering.installments_enabled;
      offeringData.installments_payment_day = offering.installments_payment_day;
      offeringData.installments_term_length = offering.installments_term_length;
    }
    if (offering.registration_start_date) {
      const isoDate = new Date(offering.registration_start_date);
      offeringData.registration_start_date = isoDate
        .toISOString()
        .split("T")[0];
    }
    if (offering.registration_end_date) {
      const isoDate = new Date(offering.registration_end_date);
      offeringData.registration_end_date = isoDate.toISOString().split("T")[0];
    }

    offeringData.offering_category = offering.offering_category;
  }

  if (offering.enrollment_open_date) {
    const isoDate = new Date(offering.enrollment_open_date);
    offeringData.enrollment_open_date = isoDate.toISOString().split("T")[0];
  }

  if (offering.enrollment_close_date) {
    const isoDate = new Date(offering.enrollment_close_date);
    offeringData.enrollment_close_date = isoDate.toISOString().split("T")[0];
  }

  if (offering.enrollment_close_date && !offering.enrollment_open_date) {
    console.error("offering close date set but open date is not set");
    throw new Error(
      "Enrollment open date is required if enrollment close date is provided"
    );
  }

  if (offering.min_participant_age) {
    offeringData.min_participant_age = offering.min_participant_age;
  }

  if (offering.max_participant_age) {
    offeringData.max_participant_age = offering.max_participant_age;
  }

  if (offering.participant_limit) {
    offeringData.participant_limit = offering.participant_limit;
  }

  const response: Offering = await adminV1PaymentsOfferingsCreate(offeringData);
  return response;
};

export const updateOfferingData = async (
  id: string,
  offering: Offering
): Promise<Offering | { error: string }> => {
  try {
    const docs: SubscriptionDocument[] = (
      offering.offering_documents || []
    ).map((doc) => ({
      asset_id: doc.asset_id,
      asset: null as any,
      created_at: "",
      id: "",
      updated_at: "",
    }));

    const offeringData: NonReadonly<Offering> = {
      amount: offering.amount,
      description: offering.description,
      documents_require_acceptance: offering.documents_require_acceptance,
      payment_frequency: offering.payment_frequency,
      is_public: offering.is_public,
      published: offering.published,
      participant_limit: offering.participant_limit,
      min_participant_age: offering.min_participant_age,
      max_participant_age: offering.max_participant_age,
      offering_documents: docs,
      title: offering.title,
      offering_category: offering.offering_category,
      installments_enabled: offering.installments_enabled,
      enrollment_close_date: offering.enrollment_close_date,
      enrollment_open_date: offering.enrollment_open_date,
      registration_start_date: offering.registration_start_date,
      registration_end_date: offering.registration_end_date,
    };

    if (offering.registration_start_date) {
      const isoDate = new Date(offering.registration_start_date);
      offeringData.registration_start_date = isoDate
        .toISOString()
        .split("T")[0];
    }

    if (offering.registration_end_date) {
      const isoDate = new Date(offering.registration_end_date);
      offeringData.registration_end_date = isoDate.toISOString().split("T")[0];
    }

    if (offering.enrollment_open_date) {
      const isoDate = new Date(offering.enrollment_open_date);
      offeringData.enrollment_open_date = isoDate.toISOString().split("T")[0];
    }

    if (offering.enrollment_close_date) {
      const isoDate = new Date(offering.enrollment_close_date);
      offeringData.enrollment_close_date = isoDate.toISOString().split("T")[0];
    }

    if (offering.enrollment_close_date && !offering.enrollment_open_date) {
      console.error("offering close date set but open date is not set");
      throw new Error(
        "Enrollment open date is required if enrollment close date is provided"
      );
    }

    if (offering.offering_category) {
      offeringData.offering_category = offering.offering_category;
    }

    if (offering.payment_frequency) {
      offeringData.payment_frequency = offering.payment_frequency;
    }

    if (offering.installments_enabled === true) {
      offeringData.installments_payment_day = offering.installments_payment_day;
      offeringData.installments_term_length = offering.installments_term_length;
    }

    const response = (await adminV1PaymentsOfferingsPartialUpdate(
      id,
      offeringData
    )) as Offering;
    return response;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getOfferingMemberships = async (
  id: string
): Promise<
  AdminV1PaymentsOfferingsSubscriberMembershipsListResult | { error: string }
> => {
  try {
    const response = await adminV1PaymentsOfferingsSubscriberMembershipsList(
      id,
      {
        limit: 10000,
        offset: 0,
      }
    );
    return response;
  } catch (e: any) {
    return { error: e.message };
  }
};

type OfferingAction =
  | { type: "publish" }
  | { type: "archive" }
  | { type: "is_public"; isPublic: boolean }
  | { type: "enrollment"; status: EnrollmentStatusEnum };

export const updateOffering = async (
  id: string,
  action: OfferingAction
): Promise<Offering | { error: string }> => {
  try {
    let updateData: Partial<NonReadonly<Offering>> = {};

    switch (action.type) {
      case "publish":
        updateData = { published: true };
        break;
      case "archive":
        updateData = { archived: true };
        break;
      case "enrollment":
        updateData = { enrollment_status: action.status };
        break;
      case "is_public":
        updateData = { is_public: action.isPublic };
        break;
    }

    const response = (await adminV1PaymentsOfferingsPartialUpdate(
      id,
      updateData
    )) as Offering;
    return response;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getSubscriberMembership = async (
  id: string
): Promise<SubscriberMembership | { error: string }> => {
  try {
    const response = await adminV1PaymentsSubscriberMembershipsRetrieve(id);
    return response;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const deleteSubscriberMembership = async (
  id: string
): Promise<{ error?: string }> => {
  try {
    await adminV1PaymentsSubscriberMembershipsDestroy(id);
    return {};
  } catch (e: any) {
    return { error: e.message };
  }
};

export const refundRegistrant = async (
  id: string,
  subscriberMembership: NonReadonly<SubscriberMembership>
): Promise<{ error?: string }> => {
  try {
    await adminV1PaymentsSubscriberMembershipsRefundCreate(
      id,
      subscriberMembership
    );
    return {};
  } catch (e: any) {
    return { error: e.message };
  }
};
