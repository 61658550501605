/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminV1PaymentsOfferingsSubscriberMembershipsListParams,
  AdminV1PaymentsSubscriberMembershipsScheduledPaymentsListParams,
  PaginatedScheduledPaymentList,
  PaginatedSubscriberMembershipList,
  SubscriberMembership
} from '.././model'
import adminV1PaymentsOfferingsSubscriberMembershipsListMutator from '../../shared/services/axios-client';
import adminV1PaymentsSubscriberMembershipsRetrieveMutator from '../../shared/services/axios-client';
import adminV1PaymentsSubscriberMembershipsDestroyMutator from '../../shared/services/axios-client';
import adminV1PaymentsSubscriberMembershipsScheduledPaymentsListMutator from '../../shared/services/axios-client';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const adminV1PaymentsOfferingsSubscriberMembershipsList = (
    offeringPk: string,
    params?: AdminV1PaymentsOfferingsSubscriberMembershipsListParams,
 options?: SecondParameter<typeof adminV1PaymentsOfferingsSubscriberMembershipsListMutator>,) => {
      return adminV1PaymentsOfferingsSubscriberMembershipsListMutator<PaginatedSubscriberMembershipList>(
      {url: `/api/admin/v1/payments/offerings/${offeringPk}/subscriber_memberships/`, method: 'GET',
        params
    },
      options);
    }
  export const adminV1PaymentsSubscriberMembershipsRetrieve = (
    id: string,
 options?: SecondParameter<typeof adminV1PaymentsSubscriberMembershipsRetrieveMutator>,) => {
      return adminV1PaymentsSubscriberMembershipsRetrieveMutator<SubscriberMembership>(
      {url: `/api/admin/v1/payments/subscriber-memberships/${id}/`, method: 'GET'
    },
      options);
    }
  export const adminV1PaymentsSubscriberMembershipsDestroy = (
    id: string,
 options?: SecondParameter<typeof adminV1PaymentsSubscriberMembershipsDestroyMutator>,) => {
      return adminV1PaymentsSubscriberMembershipsDestroyMutator<void>(
      {url: `/api/admin/v1/payments/subscriber-memberships/${id}/`, method: 'DELETE'
    },
      options);
    }
  /**
 * This endpoint is deprecated. Use payment plan past_transactions and future_transactions instead.
 * @deprecated
 * @summary This endpoint is deprecated. Use payment plan past_transactions and future_transactions instead.
 */
export const adminV1PaymentsSubscriberMembershipsScheduledPaymentsList = (
    subPk: string,
    params?: AdminV1PaymentsSubscriberMembershipsScheduledPaymentsListParams,
 options?: SecondParameter<typeof adminV1PaymentsSubscriberMembershipsScheduledPaymentsListMutator>,) => {
      return adminV1PaymentsSubscriberMembershipsScheduledPaymentsListMutator<PaginatedScheduledPaymentList>(
      {url: `/api/admin/v1/payments/subscriber-memberships/${subPk}/scheduled_payments/`, method: 'GET',
        params
    },
      options);
    }
  export type AdminV1PaymentsOfferingsSubscriberMembershipsListResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsOfferingsSubscriberMembershipsList>>>
export type AdminV1PaymentsSubscriberMembershipsRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsSubscriberMembershipsRetrieve>>>
export type AdminV1PaymentsSubscriberMembershipsDestroyResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsSubscriberMembershipsDestroy>>>
export type AdminV1PaymentsSubscriberMembershipsScheduledPaymentsListResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsSubscriberMembershipsScheduledPaymentsList>>>
