import type { PaginatedOfferingList, Offering } from "@/api/model";
import type { AdminV1PaymentsOfferingsListParams } from "@/api/model/adminV1PaymentsOfferingsListParams";
import { ref, type Ref } from "vue";
import { getOfferings } from "../api";
import { useRoute } from "vue-router";
import { OfferingTypeEnum } from "@/api/model";
const PAGE_SIZE = 20;
const FIRST_PAGE = 0;

export function useOfferings(): {
  paginatedOfferings: Ref<PaginatedOfferingList | null>;
  fetchOfferings: (
    offeringType: string,
    additionalParams?: Partial<AdminV1PaymentsOfferingsListParams>
  ) => Promise<void>;
  subscriptionFetchError: Ref<string | null>;
  currentPage: Ref<number>;
  pageSize: Ref<number>;
  totalRecords: Ref<number | undefined>;
} {
  const pageSize = ref(PAGE_SIZE);
  const currentPage = ref(FIRST_PAGE);
  const totalRecords = ref<number | undefined>(0);

  const subscriptionFetchError = ref<string | null>(null);
  const paginatedOfferings = ref<PaginatedOfferingList | null>(null);

  async function fetchOfferings(
    offeringType: string,
    additionalParams?: Partial<AdminV1PaymentsOfferingsListParams>
  ) {
    const offset = currentPage.value * pageSize.value;
    const params: AdminV1PaymentsOfferingsListParams = {
      ...additionalParams,
    };

    params.limit = pageSize.value;
    params.offset = offset;

    if (offeringType === "payment-memberships") {
      params.offering_type = OfferingTypeEnum.membership;
    } else if (offeringType === "payment-registrations") {
      params.offering_type = OfferingTypeEnum.registration;
    }

    const results = await getOfferings(params);

    if ("error" in results) {
      subscriptionFetchError.value = String(results.error);
    } else {
      paginatedOfferings.value = results;
      totalRecords.value = results.count;
    }
  }

  return {
    paginatedOfferings,
    fetchOfferings,
    subscriptionFetchError,
    currentPage,
    pageSize,
    totalRecords,
  };
}
