import { PaymentFrequencyEnum, ScheduledPaymentStatusEnum } from "@/api/model";
import { useRoute } from "vue-router";
import { parseISO, format } from "date-fns";

export const getFormattedDate = (date: string) => {
  const isoDate = parseISO(date);
  return format(isoDate, "MMM dd, yyyy");
};

const frequencyDisplayMap: Partial<Record<PaymentFrequencyEnum, string>> = {
  [PaymentFrequencyEnum.weekly]: "Weekly",
  [PaymentFrequencyEnum.every_two_weeks]: "Every 2 Weeks",
  [PaymentFrequencyEnum.monthly]: "Monthly",
  [PaymentFrequencyEnum.quarterly]: "Quarterly",
  [PaymentFrequencyEnum.annually]: "Annually",
  [PaymentFrequencyEnum.every_six_months]: "Every 6 Months",
};

export const getFrequencyDisplayValue = (
  paymentFrequency?: PaymentFrequencyEnum
) => {
  if (
    !paymentFrequency ||
    paymentFrequency === PaymentFrequencyEnum["one-time"]
  )
    return "";
  return frequencyDisplayMap[paymentFrequency];
};

export const routeCheck = () => {
  const route = useRoute();
  return route;
};

export const getScheduledPaymentStatusDisplayValue = (
  status: ScheduledPaymentStatusEnum
) => {
  switch (status) {
    case ScheduledPaymentStatusEnum.FAILED:
      return "Failed";
    case ScheduledPaymentStatusEnum.SUCCEEDED:
      return "Paid";
    case ScheduledPaymentStatusEnum.REVERSED:
      return "Refunded";
    default:
      return "";
  }
};
