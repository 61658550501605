<template>
  <BaseLayout
    title="Edit Offering"
    :breadcrumb="[
      {
        label: offeringTypePlural,
        to: {
          name: breadcrumbRoute,
        },
      },
      {
        label: offeringTitle,
      },
      {
        label: 'Edit',
      },
    ]"
  >
    <OfferingEditForm />
  </BaseLayout>
</template>

<script setup lang="ts">
import BaseLayout from "@/shared/components/BaseLayout.vue";
import OfferingEditForm from "../components/OfferingEditForm.vue";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { useRoute } from "vue-router";
import { computed } from "vue";

const route = useRoute();
const offeringTitle = route.query.title?.toString();
const offeringType = route.query.offering_type?.toString();
const offeringTypePlural = offeringType + "s";

const breadcrumbRoute = computed(() => {
  if (offeringType === "Membership") {
    return ROUTE_NAME.PAYMENT_MEMBERSHIPS;
  } else if (offeringType === "Registration") {
    return ROUTE_NAME.PAYMENT_REGISTRATIONS;
  } else {
    return "Offering";
  }
});
</script>
