<script setup lang="ts">
import type { Invoice } from "@/api/model";
import type { InvoiceStatus } from "@/types";
import { computed } from "vue";
import {
  getFormattedTitle,
  getFormattedInvoiceStatus,
  getInvoiceStatusColor,
} from "../helpers";

import { getFormattedCurrency } from "@/shared/utils/helpers";

const props = defineProps<{
  invoice: Invoice;
}>();

const { invoice } = props;

const invoiceTitle = computed(() => {
  return getFormattedTitle(invoice);
});

const invoiceStatus = computed(() => {
  return getFormattedInvoiceStatus(invoice?.invoice_status as InvoiceStatus);
});

const invoiceAmount = computed(() => {
  return getFormattedCurrency(invoice?.invoice_amount);
});

const amountPaid = computed(() => {
  return getFormattedCurrency(invoice?.payment_plan?.amount_paid || 0);
});

const outstandingAmount = computed(() => {
  return getFormattedCurrency(invoice?.payment_plan?.amount_outstanding || 0);
});
</script>

<template>
  <div class="space-y-4">
    <div class="text-xl font-bold mb-6">
      {{ invoiceTitle }}
    </div>
    <div class="flex">
      <div class="flex-1">
        <div class="mb-4">
          <p class="block text-md font-semibold text-gray-700">Due Date</p>
          <p class="font-thin">{{ invoice?.invoice_due_date }}</p>
        </div>

        <div class="mb-4">
          <p class="block text-md font-semibold text-gray-700">Total Amount</p>
          <p class="font-thin">{{ invoiceAmount }}</p>
        </div>

        <div class="flex gap-8">
          <div>
            <label
              for="status"
              class="block text-md font-semibold text-gray-700"
              >Status</label
            >
            <p
              :style="{
                color: getInvoiceStatusColor(
                  invoice.invoice_status as InvoiceStatus
                ),
              }"
              class="font-thin"
            >
              {{ invoiceStatus }}
            </p>
          </div>
          <div v-if="invoice?.installments_schedule?.length" class="flex gap-8">
            <div>
              <p class="block text-md font-semibold text-gray-700">
                Amount Paid
              </p>
              <p class="font-thin">{{ amountPaid }}</p>
            </div>

            <div>
              <p class="block text-md font-semibold text-gray-700">
                Amount Outstanding
              </p>
              <p class="font-thin">{{ outstandingAmount }}</p>
            </div>

            <div>
              <p class="block text-md font-semibold text-gray-700">
                Final Payment Date
              </p>
              <p class="font-thin">{{ invoice?.payment_plan?.end_date }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
