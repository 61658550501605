<template>
  <AppCard>
    <div>
      <h1 class="text-2xl font-bold">
        {{ subscriberMembership.subscription_participant.name }}
      </h1>
      <div class="text-lg text-gray-500">
        {{ subscriberMembership.subscription_participant.email }}
      </div>
      <div class="flex flex-wrap gap-6 border border-gray-200 p-4 mt-4">
        <div>
          <label class="text-md font-semibold text-gray-500"
            >Registrant Name</label
          >
          <p class="text-md">
            {{ subscriberMembership.associated_contact.name }}
          </p>
        </div>
        <div>
          <label class="text-md font-semibold text-gray-500"
            >Registrant Email</label
          >
          <p class="">
            {{ subscriberMembership.associated_contact.email }}
          </p>
        </div>
        <div>
          <label class="text-md font-semibold text-gray-500"
            >Sign-up Date</label
          >
          <p class="">
            {{ getFormattedDate(subscriberMembership.created_at) }}
          </p>
        </div>
        <div>
          <label class="text-md font-semibold text-gray-500"
            >All Time Revenue</label
          >
          <p class="">
            {{ getFormattedCurrency(totalCollected) }}
          </p>
        </div>
        <div>
          <label class="text-md font-semibold text-gray-500"
            >Enrollment Status</label
          >
          <p :class="isActive ? 'text-green-600' : 'text-red-600'">
            {{ isActive ? "Active" : "Canceled" }}
          </p>
        </div>
        <div>
          <label class="text-md font-semibold text-gray-500"
            >Payment Status</label
          >
          <p :class="getPaymentStatusClass(paymentStatus)">
            {{ paymentStatus }}
          </p>
        </div>
      </div>
    </div>
  </AppCard>
</template>

<script setup lang="ts">
import { LastPaymentStatusEnum, type SubscriberMembership } from "@/api/model";
import AppCard from "@/shared/components/AppCard.vue";
import { getFormattedCurrency } from "@/shared/utils/helpers";
import { computed } from "vue";

const props = defineProps<{
  subscriberMembership: SubscriberMembership;
}>();

const paymentStatus = computed(() => {
  return props.subscriberMembership.last_payment_status;
});

const getPaymentStatusClass = (status: string | null) => {
  if (!status) return "";

  return status === LastPaymentStatusEnum.Paid ||
    status === LastPaymentStatusEnum.Partially_Paid ||
    status === LastPaymentStatusEnum.None
    ? "text-green-600"
    : "text-red-600";
};

const totalCollected = computed(() => {
  return props.subscriberMembership.payment_plan.amount_paid || 0;
});

const isActive = computed(() => {
  return props.subscriberMembership.membership_cancelled_at === null;
});

const getFormattedDate = (date: string | null) => {
  if (!date) return "";
  return new Date(date).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};
</script>
