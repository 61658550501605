/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */

/**
 * * `Pending` - Pending
* `Started` - Started
* `Resolved` - Resolved
* `Rejected` - Rejected
 */
export type ChatStatus = typeof ChatStatus[keyof typeof ChatStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChatStatus = {
  Pending: 'Pending',
  Started: 'Started',
  Resolved: 'Resolved',
  Rejected: 'Rejected',
} as const;
