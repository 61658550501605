/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminV1PaymentsInvoicesListParams,
  AdminV1PaymentsInvoicesUpdate200,
  CreateInvoices,
  Invoice,
  InvoiceCreated,
  PaginatedInvoiceListList,
  UpdateInvoice
} from '.././model'
import adminV1PaymentsInvoicesListMutator from '../../shared/services/axios-client';
import adminV1PaymentsInvoicesCreateMutator from '../../shared/services/axios-client';
import adminV1PaymentsInvoicesRetrieveMutator from '../../shared/services/axios-client';
import adminV1PaymentsInvoicesUpdateMutator from '../../shared/services/axios-client';
import adminV1PaymentsInvoicesPayWithCashCreateMutator from '../../shared/services/axios-client';
import adminV1PaymentsInvoicesRefundCreateMutator from '../../shared/services/axios-client';
import adminV1PaymentsInvoicesResendEmailCreateMutator from '../../shared/services/axios-client';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const adminV1PaymentsInvoicesList = (
    params?: AdminV1PaymentsInvoicesListParams,
 options?: SecondParameter<typeof adminV1PaymentsInvoicesListMutator>,) => {
      return adminV1PaymentsInvoicesListMutator<PaginatedInvoiceListList>(
      {url: `/api/admin/v1/payments/invoices/`, method: 'GET',
        params
    },
      options);
    }
  export const adminV1PaymentsInvoicesCreate = (
    createInvoices: CreateInvoices,
 options?: SecondParameter<typeof adminV1PaymentsInvoicesCreateMutator>,) => {
      return adminV1PaymentsInvoicesCreateMutator<InvoiceCreated>(
      {url: `/api/admin/v1/payments/invoices/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createInvoices
    },
      options);
    }
  export const adminV1PaymentsInvoicesRetrieve = (
    id: string,
 options?: SecondParameter<typeof adminV1PaymentsInvoicesRetrieveMutator>,) => {
      return adminV1PaymentsInvoicesRetrieveMutator<Invoice>(
      {url: `/api/admin/v1/payments/invoices/${id}/`, method: 'GET'
    },
      options);
    }
  export const adminV1PaymentsInvoicesUpdate = (
    id: string,
    updateInvoice: UpdateInvoice,
 options?: SecondParameter<typeof adminV1PaymentsInvoicesUpdateMutator>,) => {
      return adminV1PaymentsInvoicesUpdateMutator<AdminV1PaymentsInvoicesUpdate200>(
      {url: `/api/admin/v1/payments/invoices/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateInvoice
    },
      options);
    }
  /**
 * Process a one-time cash payment for an invoice using the payabli_invoice_id.
 * @summary Process a one-time cash payment for an invoice using the payabli_invoice_id.
 */
export const adminV1PaymentsInvoicesPayWithCashCreate = (
    id: string,
 options?: SecondParameter<typeof adminV1PaymentsInvoicesPayWithCashCreateMutator>,) => {
      return adminV1PaymentsInvoicesPayWithCashCreateMutator<void>(
      {url: `/api/admin/v1/payments/invoices/${id}/pay_with_cash/`, method: 'POST'
    },
      options);
    }
  /**
 * Refund an invoice and all associated payments
 * @summary Refund an invoice and all associated payments
 */
export const adminV1PaymentsInvoicesRefundCreate = (
    id: string,
 options?: SecondParameter<typeof adminV1PaymentsInvoicesRefundCreateMutator>,) => {
      return adminV1PaymentsInvoicesRefundCreateMutator<void>(
      {url: `/api/admin/v1/payments/invoices/${id}/refund/`, method: 'POST'
    },
      options);
    }
  /**
 * Resend the invoice email to the recipient
 * @summary Resend the invoice email to the recipient
 */
export const adminV1PaymentsInvoicesResendEmailCreate = (
    id: string,
 options?: SecondParameter<typeof adminV1PaymentsInvoicesResendEmailCreateMutator>,) => {
      return adminV1PaymentsInvoicesResendEmailCreateMutator<void>(
      {url: `/api/admin/v1/payments/invoices/${id}/resend_email/`, method: 'POST'
    },
      options);
    }
  export type AdminV1PaymentsInvoicesListResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsInvoicesList>>>
export type AdminV1PaymentsInvoicesCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsInvoicesCreate>>>
export type AdminV1PaymentsInvoicesRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsInvoicesRetrieve>>>
export type AdminV1PaymentsInvoicesUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsInvoicesUpdate>>>
export type AdminV1PaymentsInvoicesPayWithCashCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsInvoicesPayWithCashCreate>>>
export type AdminV1PaymentsInvoicesRefundCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsInvoicesRefundCreate>>>
export type AdminV1PaymentsInvoicesResendEmailCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsInvoicesResendEmailCreate>>>
