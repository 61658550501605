/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminV1PaymentsOfferingsListParams,
  Offering,
  OfferingUpdate,
  PaginatedOfferingList,
  PatchedOfferingUpdate
} from '.././model'
import adminV1PaymentsOfferingsListMutator from '../../shared/services/axios-client';
import adminV1PaymentsOfferingsCreateMutator from '../../shared/services/axios-client';
import adminV1PaymentsOfferingsRetrieveMutator from '../../shared/services/axios-client';
import adminV1PaymentsOfferingsPartialUpdateMutator from '../../shared/services/axios-client';
import adminV1PaymentsOfferingsCloseEnrollmentCreateMutator from '../../shared/services/axios-client';
import adminV1PaymentsOfferingsOpenEnrollmentCreateMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const adminV1PaymentsOfferingsList = (
    params?: AdminV1PaymentsOfferingsListParams,
 options?: SecondParameter<typeof adminV1PaymentsOfferingsListMutator>,) => {
      return adminV1PaymentsOfferingsListMutator<PaginatedOfferingList>(
      {url: `/api/admin/v1/payments/offerings/`, method: 'GET',
        params
    },
      options);
    }
  export const adminV1PaymentsOfferingsCreate = (
    offering: NonReadonly<Offering>,
 options?: SecondParameter<typeof adminV1PaymentsOfferingsCreateMutator>,) => {
      return adminV1PaymentsOfferingsCreateMutator<Offering>(
      {url: `/api/admin/v1/payments/offerings/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: offering
    },
      options);
    }
  export const adminV1PaymentsOfferingsRetrieve = (
    id: string,
 options?: SecondParameter<typeof adminV1PaymentsOfferingsRetrieveMutator>,) => {
      return adminV1PaymentsOfferingsRetrieveMutator<Offering>(
      {url: `/api/admin/v1/payments/offerings/${id}/`, method: 'GET'
    },
      options);
    }
  export const adminV1PaymentsOfferingsPartialUpdate = (
    id: string,
    patchedOfferingUpdate: NonReadonly<PatchedOfferingUpdate>,
 options?: SecondParameter<typeof adminV1PaymentsOfferingsPartialUpdateMutator>,) => {
      return adminV1PaymentsOfferingsPartialUpdateMutator<OfferingUpdate>(
      {url: `/api/admin/v1/payments/offerings/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedOfferingUpdate
    },
      options);
    }
  export const adminV1PaymentsOfferingsCloseEnrollmentCreate = (
    id: string,
    offering: NonReadonly<Offering>,
 options?: SecondParameter<typeof adminV1PaymentsOfferingsCloseEnrollmentCreateMutator>,) => {
      return adminV1PaymentsOfferingsCloseEnrollmentCreateMutator<Offering>(
      {url: `/api/admin/v1/payments/offerings/${id}/close-enrollment/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: offering
    },
      options);
    }
  export const adminV1PaymentsOfferingsOpenEnrollmentCreate = (
    id: string,
    offering: NonReadonly<Offering>,
 options?: SecondParameter<typeof adminV1PaymentsOfferingsOpenEnrollmentCreateMutator>,) => {
      return adminV1PaymentsOfferingsOpenEnrollmentCreateMutator<Offering>(
      {url: `/api/admin/v1/payments/offerings/${id}/open-enrollment/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: offering
    },
      options);
    }
  export type AdminV1PaymentsOfferingsListResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsOfferingsList>>>
export type AdminV1PaymentsOfferingsCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsOfferingsCreate>>>
export type AdminV1PaymentsOfferingsRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsOfferingsRetrieve>>>
export type AdminV1PaymentsOfferingsPartialUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsOfferingsPartialUpdate>>>
export type AdminV1PaymentsOfferingsCloseEnrollmentCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsOfferingsCloseEnrollmentCreate>>>
export type AdminV1PaymentsOfferingsOpenEnrollmentCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsOfferingsOpenEnrollmentCreate>>>
