<template>
  <BaseLayout
    title="View Invoice"
    :breadcrumb="[
      {
        label: 'Invoices',
        to: {
          name: ROUTE_NAME.PAYMENT_INVOICES,
        },
      },
      {
        label: invoice?.invoice_title,
      },
    ]"
  >
    <div>
      <div class="fixed-action-bar justify-end rounded-md p-2">
        <Button
          icon="pi pi-pencil"
          label="Edit"
          severity="secondary"
          :disabled="disablabledEditButton"
          @click="onClickEdit"
          class="mr-2"
        />
        <ActionsMenu :menu-items="actionMenuItems" />
      </div>
    </div>
    <div v-if="invoice">
      <Invoice :invoice="invoice" />
    </div>
  </BaseLayout>
  <ConfirmDialog group="refund">
    <template #container="{ message, acceptCallback, rejectCallback }">
      <div class="flex flex-col p-8 bg-surface-0 dark:bg-surface-900 rounded">
        <h3 class="text-xl font-bold mb-4">{{ message.header }}</h3>
        <p class="whitespace-pre-line mb-6">{{ message.message }}</p>
        <div class="flex justify-end gap-6">
          <button
            type="button"
            class="text-gray-600 hover:text-gray-800"
            @click="rejectCallback"
          >
            Cancel
          </button>
          <Button @click="acceptCallback"> Yes, refund </Button>
        </div>
      </div>
    </template>
  </ConfirmDialog>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";

import BaseLayout from "@/shared/components/BaseLayout.vue";
import Invoice from "../components/Invoice.vue";
import Button from "primevue/button";
import ActionsMenu from "@/shared/components/ActionsMenu.vue";
import ConfirmDialog from "primevue/confirmdialog";

import { useInvoice } from "../composables/useInvoice";
import { useResendPortalEmail } from "../composables/useResendPortalEmail";
import { useRefundInvoice } from "../composables/useRefundInvoice";

import { ROUTE_NAME } from "@/shared/constants/routes";
import {
  CANCELLED_INVOICE,
  PAID_INVOICE,
  PARTIALLY_PAID_INVOICE,
} from "../constants";
import { updateInvoice, markInvoiceAsPaid } from "../api";
import type { UpdateInvoice } from "@/api/model";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import { getFormattedCurrency } from "@/shared/utils/helpers";

const menu = ref();
const { fetchInvoice, invoice } = useInvoice();
const confirm = useConfirm();

const disableResendEmail = computed(() => {
  return [CANCELLED_INVOICE, PAID_INVOICE, PARTIALLY_PAID_INVOICE].includes(
    invoice.value?.invoice_status as string
  );
});

const enableRefund = computed(() => {
  return [PAID_INVOICE, PARTIALLY_PAID_INVOICE].includes(
    invoice.value?.invoice_status as string
  );
});

const actionMenuItems = computed(() => [
  {
    label: "View in Payment Portal",
    icon: "pi pi-external-link",
    command: linkToPaymentPortal,
  },
  {
    label: "View in Payabli",
    icon: "pi pi-external-link",
    command: linkToPayabli,
  },
  {
    label: "Resend Email",
    icon: "pi pi-envelope",
    command: resendEmail,
    disabled: disableResendEmail.value,
  },
  {
    label: "Refund Invoice",
    icon: "pi pi-replay",
    command: confirmRefundInvoice,
    disabled: !enableRefund.value,
  },
  {
    label: "Mark as Paid",
    icon: "pi pi-dollar",
    command: markAsPaid,
    disabled: disabledPaidButton.value,
  },
  {
    label: "Cancel Invoice",
    icon: "pi pi-times",
    command: () => {
      cancelInvoice();
    },
    disabled: disablabledEditButton.value,
  },
]);

const route = useRoute();
const toast = useToast();

const invoiceId = route.params.id.toString();

onMounted(async () => {
  await fetchInvoice(invoiceId);
});

const { resendEmail: resendPortalEmail, isLoading: isResendingEmail } =
  useResendPortalEmail();

const {
  refundInvoice: refundInvoiceRequest,
  isLoading: isRefunding,
  errorMessage: refundError,
} = useRefundInvoice();

const handleRefundInvoice = async () => {
  await refundInvoiceRequest(invoiceId);
  // Refetch regardless of success or failure to get the latest invoice status, accounting for partial refunds
  await fetchInvoice(invoiceId);

  if (refundError.value) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Invoice could not be fully refunded",
      life: 3000,
    });
    return;
  }

  toast.add({
    severity: "success",
    summary: "Success",
    detail: "Invoice has been refunded successfully",
    life: 3000,
  });
};

const confirmRefundInvoice = () => {
  if (!invoice.value) return;

  confirm.require({
    group: "refund",
    header: "Confirm Refund",
    message: `Are you sure you want to refund this invoice?\n\n${getFormattedCurrency(invoice.value.invoice_amount)} will be refunded to ${invoice.value.invoice_recipient.name}`,
    acceptLabel: "Yes, refund",
    rejectLabel: "Cancel",
    accept: () => {
      handleRefundInvoice();
    },
  });
};

const resendEmail = async () => {
  await resendPortalEmail(invoiceId);
  toast.add({
    severity: "success",
    summary: "Success",
    detail: "Email has been resent successfully",
    life: 3000,
  });
};

const onClickEdit = () => {
  router.push({ name: ROUTE_NAME.EDIT_INVOICE, params: { id: invoiceId } });
};

const markAsPaid = async () => {
  if (!invoice.value) return;

  await markInvoiceAsPaid(invoiceId);
  await fetchInvoice(invoiceId);
  toast.add({
    severity: "success",
    summary: "Success",
    detail: "Invoice has been marked as paid successfully",
    life: 3000,
  });
};

const cancelInvoice = async () => {
  if (!invoice.value) return;

  const params: UpdateInvoice = {
    cancel: true,
    installments_enabled: invoice.value.installments_enabled || false,
    installments_payment_day: invoice.value.installments_payment_day,
    installments_term_length: invoice.value.installments_term_length,
    invoice_due_date: invoice.value.invoice_due_date,
    invoice_recipient_id: invoice.value.invoice_recipient.id,
    invoice_title: invoice.value.invoice_title,
    items: invoice.value.items,
    profile_id: invoice.value.profile.id,
  };

  await updateInvoice(invoiceId, params);
  await fetchInvoice(invoiceId);
  toast.add({
    severity: "success",
    summary: "Success",
    detail: "Invoice has been canceled successfully",
    life: 3000,
  });
};

const linkToPayabli = () => {
  const url = invoice.value?.payabli_payhub_invoice_url;

  if (!url) return;

  window.open(url, "_blank");
};

const linkToPaymentPortal = () => {
  const url = invoice.value?.portal_invoice_url;

  if (!url) return;

  window.open(url, "_blank");
};

const disablabledEditButton = computed(() => {
  const notEditableStatuses = [
    PAID_INVOICE,
    CANCELLED_INVOICE,
    PARTIALLY_PAID_INVOICE,
  ];

  if (!invoice?.value?.invoice_status) return;
  if (!invoice.value.is_tfa_created_invoice) return;

  return notEditableStatuses.includes(invoice.value?.invoice_status);
});

const disabledPaidButton = computed(() => {
  const notPayableStatuses = [
    PAID_INVOICE,
    CANCELLED_INVOICE,
    PARTIALLY_PAID_INVOICE,
  ];

  if (!invoice?.value?.invoice_status) return;
  if (!invoice.value.is_tfa_created_invoice) return;

  return notPayableStatuses.includes(invoice.value?.invoice_status);
});
</script>

<style scoped>
.fixed-action-bar {
  display: flex;
  width: 100%;
}
</style>
