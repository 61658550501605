<template>
  <BaseLayout
    title="View Registration"
    :breadcrumb="[
      {
        label: subscriberMembership?.offering.title,
        to: {
          name: ROUTE_NAME.PAYMENT_REGISTRATIONS_VIEW,
          params: {
            id: subscriberMembership?.offering.id,
          },
        },
      },
      {
        label: subscriberMembership?.subscription_participant.name,
      },
    ]"
  >
    <div class="flex justify-end mb-4 gap-2">
      <ActionsMenu
        :menu-items="menuItems"
        :dangerous-action="cancelRegistrantAction"
      />
    </div>
    <div>
      <div v-if="subscriberMembership">
        <RegistrantDetail :subscriber-membership="subscriberMembership" />
      </div>
      <div v-else class="text-center p-4 text-gray-500">
        Registrant not found.
      </div>
    </div>
    <ConfirmDialog group="registrant">
      <template #container="{ message, acceptCallback, rejectCallback }">
        <div class="flex flex-col p-8 bg-surface-0 dark:bg-surface-900 rounded">
          <h3 class="text-xl font-bold mb-4">{{ message.header }}</h3>
          <p class="whitespace-pre-line mb-6">{{ message.message }}</p>
          <div class="flex flex-col gap-2">
            <label class="text-sm"
              >Type "{{ message.confirmationText }}" to confirm:</label
            >
            <InputText
              v-model="deleteConfirmText"
              :placeholder="message.confirmationText"
              class="w-full"
            />
          </div>
          <div class="flex justify-end gap-4 mt-6">
            <button
              type="button"
              class="text-gray-600 hover:text-gray-800"
              @click="rejectCallback"
            >
              No
            </button>
            <Button
              :disabled="deleteConfirmText !== message.confirmationText"
              severity="danger"
              @click="acceptCallback"
            >
              Yes, {{ message.confirmationText }}
            </Button>
          </div>
        </div>
      </template>
    </ConfirmDialog>
  </BaseLayout>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import BaseLayout from "@/shared/components/BaseLayout.vue";
import ActionsMenu from "@/shared/components/ActionsMenu.vue";
import RegistrantDetail from "@/modules/offerings/components/RegistrantDetail.vue";
import { addMixpanelEvent, EVENT_NAMES } from "@/shared/utils/analytics";
import { useSubscriberMembership } from "@/modules/offerings/composables/useSubscriberMembership";
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { deleteSubscriberMembership } from "@/modules/offerings/api";
import { refundRegistrant } from "@/modules/offerings/api";

const route = useRoute();
const toast = useToast();
const { subscriberMembership, fetchSubscriberMembership } =
  useSubscriberMembership();

const confirm = useConfirm();
const deleteConfirmText = ref("");
const refundConfirmText = ref("");

const disableCancelAction = computed(() => {
  if (!subscriberMembership.value) {
    return true;
  }

  return !!subscriberMembership.value?.membership_cancelled_at;
});

const CANCEL_SUBSCRIBER_MEMBERSHIP_MESSAGES = {
  group: "registrant",
  title: "Cancel Registration",
  confirmationText: "Cancel",
  header: "Cancel Registration",
  message:
    "Are you sure you want to cancel this registration?\n\nThis action cannot be undone. Any unprocessed payments will continue to be processed.",
};

const REFUND_REGISTRANT_MESSAGES = {
  group: "registrant",
  title: "Refund Registration",
  confirmationText: "Refund",
  header: "Refund Registration",
  message:
    "Are you sure you want to refund this registration?\n\nThis action cannot be undone and will result in all unprocessed payments being canceled. It will not affect the participant's registration status.",
};

const cancelRegistrantAction = computed(() => ({
  label: "Cancel",
  icon: "pi pi-trash",
  disabled: disableCancelAction.value,
  dangerous: true as const,
  command: () => {
    deleteConfirmText.value = "";
    confirm.require({
      ...CANCEL_SUBSCRIBER_MEMBERSHIP_MESSAGES,
      accept: async () => {
        try {
          if (
            !subscriberMembership.value?.id ||
            !subscriberMembership.value?.offering?.id
          ) {
            throw new Error("Invalid membership data");
          }

          const result = await deleteSubscriberMembership(
            subscriberMembership.value.id
          );

          if ("error" in result) {
            throw new Error(result.error);
          }

          addMixpanelEvent(EVENT_NAMES.REGISTRANTS.RegistrantCanceled);

          await fetchSubscriberMembership(subscriberMembership.value.id);

          toast.add({
            severity: "success",
            summary: "Success",
            detail: "Registration canceled successfully",
            life: 3000,
          });
        } catch (error) {
          console.error("Failed to cancel registration:", error);
          toast.add({
            severity: "error",
            detail: "Failed to cancel registration",
            life: 10000,
          });
        }
      },
    });
  },
}));

const linkToPayabli = () => {
  const url =
    subscriberMembership.value?.associated_contact?.payabli_customer_url;

  if (!url) return;

  window.open(url, "_blank");
};

const menuItems = [
  {
    label: "Email",
    icon: "pi pi-envelope",
    command: () => {
      const email = subscriberMembership.value?.associated_contact?.email || "";
      window.location.href = `mailto:${email}`;
    },
  },
  {
    label: "View in Payabli",
    icon: "pi pi-external-link",
    command: linkToPayabli,
  },
  {
    label: "Refund",
    icon: "pi pi-replay",
    command: () => {
      refundConfirmText.value = "";
      confirm.require({
        ...REFUND_REGISTRANT_MESSAGES,
        accept: async () => {
          try {
            if (!subscriberMembership.value?.id) {
              throw new Error("Invalid membership data");
            }

            const result = await refundRegistrant(
              subscriberMembership.value.id,
              subscriberMembership.value
            );

            if ("error" in result) {
              throw new Error(result.error);
            }

            addMixpanelEvent(EVENT_NAMES.REGISTRANTS.RegistrantRefunded);

            toast.add({
              severity: "success",
              summary: "Success",
              detail: "Registration refunded successfully",
              life: 3000,
            });
          } catch (error) {
            console.error("Failed to refund registration:", error);
            toast.add({
              severity: "error",
              detail: "Failed to fully refund registration",
            });
          } finally {
            // Refetch regardless of success or failure to get the latest registrant state, accounting for partial refunds
            if (subscriberMembership.value) {
              fetchSubscriberMembership(subscriberMembership.value.id);
            }
          }
        },
      });
    },
  },
];

onMounted(async () => {
  await fetchSubscriberMembership(route.params.id as string);
});
</script>
