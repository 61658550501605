/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */

export type LastPaymentStatusEnum = typeof LastPaymentStatusEnum[keyof typeof LastPaymentStatusEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LastPaymentStatusEnum = {
  Paid: 'Paid',
  Partially_Paid: 'Partially Paid',
  Failed: 'Failed',
  Refunded: 'Refunded',
  None: 'None',
} as const;
